import React, { useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '@material-ui/core/ButtonBase'
import WarningIcon from '@material-ui/icons/Warning'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Grid from '@material-ui/core/Grid'
import { useCodePanel, CodePanelActions } from './contexts/CodePanelContext'
import { useUserConfig } from './contexts/UserConfigContext'
import { useFileManagement, FileManagementActions } from './contexts/FileManagementContext'
import { isFileNameInvalid } from './utils/file-operation-utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import authFetchHandler from './tools/auth/authFetchHandler'
import { FetchResp } from './MyClassesDialog/FetchResp'
import { Button } from '@material-ui/core'
import { copyToClipboard } from './utils/copyToClipboard'
import { readSharedFileFromRepo } from './content-manager/code-files/code-file-store'
import { genContrastColor } from './utils/shade-highlight-tools'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useSnackbar } from 'notistack'
import { FIRIA_GREEN_RGB } from './FiriaGreenButton'

const useStyles = makeStyles((theme) => {
  return ({
    errorModalContent: {
      backgroundColor:'white',
      padding:10,
      height:'100%',
      width:'100%',
      display:'flex',
      flexDirection:'row',
      alignItems:'flex-start',
      justifyContent:'space-between',
    },
    modal:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height:'100%',
      width:'100%',
    },
    textContent:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
    },
    textField:{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap:5,
      width:'95%',
    },
    text: {
      fontSize: 14,
    },
    errorModalText: {
      fontSize: 14,
      color:'black',
    },
    paper:{
      outlineStyle:'none',
      padding:10,
      paddingTop:15,
      paddingBottom:15,
    },
    errorButtonContainer: {
      backgroundColor:'#f0f0f0',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    filePropsFooterDataContainer: {
      display:'flex',
      flexDirection:'row',
      alignItems:'flex-start',
      justifyContent:'flex-end',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems:'flex-end',
      paddingBottom: 7,
    },
    bottomContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width:'95%',
    },
    darkFileNameInput: {
      '& .MuiOutlinedInput-root': {
        height: 45,
        '& fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiInputLabel-root': {
        top: -5,
      },
      '& .MuiInputLabel-shrink':{
        top: 0,
        color:'white',
      },
    },
    lightFileNameInput: {
      '& .MuiOutlinedInput-root': {
        height: 45,
      },
      '& .MuiInputLabel-root': {
        top: -5,
      },
      '& .MuiInputLabel-shrink':{
        top: 0,
      },
    },
    warningIcon: {
      fontSize:60,
      color:'#fce100',
    },
    filenameExistsContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width:'100%',
      minHeight: 60,
    },
    invalidCharContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems: 'space-between',
      width:'100%',
    },
    whiteSpaceFilenameContainer: {
      display:'flex',
      flexDirection:'column',
      justifyContent: 'center',
      width: '100%',
      minHeight: 60,
    },
    gridPaper: {
      color: 'black',
      backgroundColor: '#dfdfdf',
      width: 26,
      height:26,
      borderRadius: 1,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    circularProgress: {
      color: theme.palette.type === 'dark' ? 'white' : 'black',
    },
  })
})

const WindowsButtonBase = withStyles({
  root: {
    width: 86,
    height: 24,
    fontWeight:'lighter',
    fontSize: 13,
    borderRadius: 0,
  },
})(ButtonBase)

const WindowsDarkButton = withStyles({
  root: {
    outline: '1px solid',
    border: '1px solid #9b9b9b',
    '&:hover': {
      backgroundColor:'#454545',
    },
    '&:disabled': { opacity: .1 },
    '&:focus': {
      border: '1px solid',
    },
  },
})(WindowsButtonBase)

const WindowsLightButton = withStyles({
  root: {
    color:'black',
    border: '1px solid #adadad',
    '&:hover': {
      backgroundColor:'#e5f1fb',
      border: '1px solid #0078d7',
    },
    '.Mui-disabled': { opacity: .1 },
    '&:focus': {
      border: '2px solid #0078d7',
    },
  },
})(WindowsButtonBase)

export const OutlinedTextField = (props) => {
  const [userConfigState] = useUserConfig()
  const classes = useStyles()

  return (
    <TextField
      variant='outlined'
      {...props}
      className={
        `
        ${userConfigState.theme === 'light' ? classes.fileNameInput:classes.darkFileNameInput}
        ${props.className && null}
        `
      }
    >
      {props.children}
    </TextField>
  )
}

const WindowsButton = ({ onClick, disabled, label, focus }) => {
  const [userConfigState] = useUserConfig()
  switch (userConfigState.theme) {
    case 'dark':
      return (
        <WindowsDarkButton autoFocus={focus} onClick={onClick} disabled={disabled}>
          {label}
        </WindowsDarkButton>
      )
    default:
      return (
        <WindowsLightButton autoFocus={focus} onClick={onClick} disabled={disabled}>
          {label}
        </WindowsLightButton>
      )
  }
}

function ErrorModal(props) {
  const classes = useStyles()

  return (
    <Modal
      open={props.open}
      className={classes.modal}
      style={{height: props.height ? props.height : '78%'}}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick' ){
          return
        }
        props.handleClose()
      }}
    >
      <div style={{width:400, height:200, outlineStyle:'none'}}>
        <div>
          <div className={classes.errorModalContent} style={{gap:20}}>
            <WarningIcon stroke={'#d19800'} strokeWidth={0.7} className={classes.warningIcon}/>
            {props.error?.type === 'whitespace' ?
              <div className={classes.whiteSpaceFilenameContainer}>
                <Typography className={classes.errorModalText} noWrap style={{paddingRight:4}}>{'Filename cannot be empty!'}</Typography>
              </div>:
              null}
            {props.error?.type === 'char' ?
              <div className={classes.invalidCharContainer}>
                <div className={classes.textContent}>
                  <Typography className={classes.errorModalText} style={{paddingRight:4}}>{'Filename includes invalid character(s):'}</Typography>
                </div>
                <Divider style={{backgroundColor: '#dfdfdf', marginBottom: 2}}/>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                >
                  {props.error.invalidChars.map((char) => {
                    return (
                      <Grid style={{margin:2}} key={char} item>
                        <Paper className={classes.gridPaper}>{char === ' ' ? '⎵' : char}</Paper>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>:
              null}
            {props.error?.type === 'exists' ?
              <div className={classes.filenameExistsContainer}>
                <div className={classes.textContent}>
                  <Typography className={classes.errorModalText} >{'Filename "'}</Typography>
                  <Typography className={classes.errorModalText} style={{maxWidth:130}} noWrap >{props.error.fileName}</Typography>
                  <Typography className={classes.errorModalText} >{'" already exists!'}</Typography>
                </div>
                <Typography className={classes.errorModalText} noWrap>Please delete the existing file first.</Typography>
              </div>:
              null}
          </div>
        </div>
        <Divider style={{backgroundColor: '#dfdfdf'}}/>
        <div className={classes.errorButtonContainer} style={{gap:10}}>
          <WindowsLightButton
            onClick={props.onClose}
          >
            Ok
          </WindowsLightButton>
          <div></div>
        </div>
      </div>
    </Modal>
  )
}

function FilePropertiesModal({ open, close, uid }) {
  const [file, setFile] = React.useState()
  const [nameInput, setNameInput] = React.useState('')
  const [error, setError] = React.useState(null)
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
  const componentMounted = useRef(null)
  const [fileManagementState, fileManagementDispatch] = useFileManagement()
  const classes = useStyles()

  React.useEffect(() => {
    setFile(fileManagementState.tree?.get(uid))
  }, [fileManagementState.tree, uid])

  const handleClose = React.useCallback(() => {
    close()
    setNameInput('')
  }, [close])

  const changeName = React.useCallback(async () => {
    if (!file?.name || nameInput === file?.name) {
      return
    }
    const nameInvalid = isFileNameInvalid(nameInput)
    if (nameInvalid) {
      setError(nameInvalid)
    } else {
      try {
        await fileManagementDispatch({ type: FileManagementActions.RENAME, uid, name: nameInput })
        if (!!componentMounted.current) {
          handleClose()
        }
      } catch (err) {
        setError({type: 'exists', fileName: nameInput})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, handleClose, nameInput, uid])

  React.useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <div>
      <Modal
        open={open}
        onClose={(ev, reason) => {
          if (reason === 'backdropClick' ){
            return
          }
          handleClose()
        }}
        className={classes.modal}
        style={{height:'100%'}}
      >
        <Paper className={classes.paper} style={{width:530, height:180}}>
          <div className={classes.modalContent}>
            <div style={{width:'95%'}}>
              <div style={{display:'flex', justifyContent: 'space-between', paddingBottom:5}}>
                <div className={classes.textContent} style={{gap:4}}>
                  <Typography className={classes.text}>{'File: '}</Typography>
                  <Typography className={classes.text} style={{maxWidth:310}} noWrap>{file?.name}</Typography>

                </div>
                <WindowsButton
                  onClick={() => setOpenConfirmDelete(true)}
                  label={
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                      <div style={{width:19, paddingTop:2}}><DeleteIcon style={{fontSize: 16}}/></div>

                      <div style={{width:'100%'}}>{'Delete'}</div>

                      <div style={{width:19}}></div>
                    </div>
                  }
                />
              </div>
              <Divider />
            </div>
            <div className={classes.textField}>
              <OutlinedTextField
                fullWidth
                label={'Rename'}
                value={nameInput}
                onKeyDown={(ev)=>{
                  if (ev.key === 'Enter'){
                    changeName()
                  }
                }}
                onChange={ev => (setNameInput(ev.target.value))}
              />
            </div>
            <div className={classes.bottomContent}>
              <div className={classes.filePropsFooterDataContainer} style={{gap:15}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', gap:4}}>
                  <Typography className={classes.text}>{'Created On:'}</Typography>
                  <Typography className={classes.text}>{'Last Modified:'}</Typography>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', gap:4}}>
                  <Typography className={classes.text}>{new Date(file?.createdAt)?.toLocaleString()}</Typography>
                  <Typography className={classes.text}>{new Date(file?.modifiedAt)?.toLocaleString()}</Typography>
                </div>
              </div>
              <div className={classes.buttonContainer} style={{gap:10}}>
                <WindowsButton
                  onClick={changeName}
                  label={'OK'}
                />
                <WindowsButton
                  onClick={handleClose}
                  label={'Cancel'}
                />
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
      <ConfirmDeleteFileModal
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        closeParentModal={handleClose}
        uid={uid}
        name={file?.name}
      />
      <ErrorModal height={'100%'} error={error} open={Boolean(error)} onClose={()=>setError(null)}/>
    </div>
  )
}

function ConfirmDeleteFileModal({ open, onClose, closeParentModal, uid, name }) {
  const classes = useStyles()
  const [, codePanelDispatch] = useCodePanel()
  const [, fileManagementDispatch] = useFileManagement()
  const componentMounted = useRef(null)
  const handleClose = () => {
    onClose()
  }

  const deleteFile = async () => {
    try {
      // If any tabs are open for this file, close it first
      await codePanelDispatch({ type: CodePanelActions.CLOSE_TABS, fileUids: [uid]})
      fileManagementDispatch({ type: FileManagementActions.DELETE, uid })
    } catch (err) {
      console.error(err)
    }

    if (componentMounted.current) {
      onClose()
      closeParentModal()
    }
  }

  React.useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        className={classes.modal}
        style={{height:'100%'}}
        disableBackdropClick
      >
        <Paper className={classes.paper} style={{width:300, height:115}}>
          <div className={classes.modalContent}>
            <div style={{width:'95%', flexDirection:'column', alignItems:'center', gap:20}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:4}}>
                <Typography className={classes.text}>{'Are you sure you want to delete'}</Typography>
                <Typography className={classes.text} style={{maxWidth:275}} noWrap>{name+'?'}</Typography>
              </div>
              <div>
                <Divider style={{marginTop:4, marginBottom: 4}}/>
              </div>
            </div>
            <div className={classes.buttonContainer} style={{gap:10, paddingBottom: 0}}>
              <WindowsButton
                onClick={async () => await deleteFile()}
                label={'Yes'}
              />
              <WindowsButton
                onClick={handleClose}
                label={'No'}
                focus={true}
              />
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

function ShareFileModal({ open, onClose }) {
  const [codePanelState] = useCodePanel()
  const [error, setError] = React.useState(null)
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const [url, setURL] = React.useState('')
  const classes = useStyles()
  const snacks = useSnackbar()

  const openedFileId = codePanelState?.tabs?.focused
  React.useEffect(() => {
    async function getShareFileURL() {
      setFetchStatus('waiting')
      let resp
      try {
        resp = await authFetchHandler(`files/share-code/${openedFileId}`)
      } catch {
        //
      }

      if (resp?.status !== 200) {
        setError(true)
        setFetchStatus('failed')
        return
      }
      setURL(await resp.text())
      setFetchStatus('success')
    }

    if (open) {
      getShareFileURL()
    }
  }, [open, openedFileId])

  function copy() {
    copyToClipboard(url)
    snacks.enqueueSnackbar('Copied to clipboard', { variant: 'info', key: 'clip', preventDuplicate: true, disableWindowBlurListener: true, autoHideDuration: 3000 })
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={(ev, reason) => {
          if (reason === 'backdropClick' ){
            return
          }
          onClose()
        }}
        className={classes.modal}
        style={{height:'70%'}}
        keepMounted
      >
        <Paper className={classes.paper} style={{width:600, height:200}}>
          <div className={classes.modalContent}>
            <div style={{width:'95%'}}>
              {'Share File'}
              <Divider />
            </div>
            <div >
              {fetchStatus === 'success' ?
                <>
                  <Button onClick={copy} disabled={fetchStatus !== 'success'} style={{textTransform: 'none', border: `1px solid ${genContrastColor(0.1)}`, padding: 0}}>
                    <Typography style={{fontSize: 16, padding: '0px 10px', textOverflow: 'ellipsis', overflow:'hidden', whiteSpace:'nowrap', width: 500}}>
                      {url}
                    </Typography>
                    <div style={{display: 'flex', alignItems: 'center', backgroundColor: `rgba(${FIRIA_GREEN_RGB})`, borderRadius: 2, padding: 8, height: 40}}>
                      <FileCopyOutlinedIcon style={{fontSize: 18}}/>
                    </div>
                  </Button>
                  <Typography style={{paddingTop: 5, fontSize: 15}}>This link will expire in <i>90 days.</i></Typography>
                </>:
                null
              }

              {fetchStatus === 'waiting' ?
                <>
                  <Button onClick={copy} disabled={fetchStatus !== 'success'} style={{textTransform: 'none', border: `1px solid ${genContrastColor(0.1)}`, padding: 0, width: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, width: 500}}>
                      <div style={{width: 24}} />
                      <Typography>
                        {'Generating shareable link'}
                      </Typography>
                      <FetchResp
                        fetchStatus={fetchStatus}
                        errorMsg={error}
                        open={open}
                        setErrorMsg={setError}
                        setFetchStatus={setFetchStatus}
                      />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', backgroundColor: `rgba(${FIRIA_GREEN_RGB})`, borderRadius: 2, padding: 8, height: 40}}>
                      <FileCopyOutlinedIcon style={{fontSize: 18}}/>
                    </div>
                  </Button>
                  <Typography style={{paddingTop: 5, fontSize: 15}}>This link will expire in <i>90 days.</i></Typography>
                </>:
                null}


              {fetchStatus === 'error' ?
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'flex-start', gap: 20}}>
                  <ErrorOutlineIcon color='error' style={{fontSize: 40}}/>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', gap: 10}}>
                    <Typography style={{fontSize: 17}}>
                      {'An unexpected error occurred!'}
                    </Typography>
                    <Typography style={{fontSize: 15}}>
                      {'Try again by closing and re-opening the dialog.'}
                    </Typography>
                    <Typography style={{fontSize: 15}}>
                      {'If the problem persists, please contact support.'}
                    </Typography>
                  </div>
                  <div style={{width: 40}}/>
                </div>:
                null
              }
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', gap:10, width:'95%'}}>
              <WindowsButton
                onClick={onClose}
                label={'Close'}
              />
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}


function NewFileModal({ mode, onClose, parentFolder }) {
  const open = Boolean(mode)
  const create = mode === 'Create'
  const copy = mode === 'Make A Copy'
  const saveAs = mode === 'Save As' || copy
  const [codePanelState, dispatch] = useCodePanel()
  const [nameInput, setNameInput] = React.useState('')
  const [error, setError] = React.useState(null)
  const componentMounted = useRef(null)
  const textInputRef = useRef(null)
  const selectText = useRef(false)
  const [newFileResolved, setNewFileResolved] = React.useState(true)
  const [, fileManagementDispatch] = useFileManagement()
  const classes = useStyles()
  const handleClose = React.useCallback(() => {
    setNameInput('')
    onClose()
  }, [onClose])
  // TODO: Implement UI
  /*
  const newFolder = async () => {
    try {
      await fileManagementDispatch({ type: FileManagementActions.NEW_FOLDER, parentFolder, nameInput })
      if (componentMounted.current) {
        handleClose()
      }
    } catch (err) {
      console.error(err)
      setErrorOpen(true)
    }
  }
  */
  const newFile = React.useCallback(async () => {
    if (!newFileResolved) {
      return
    }

    setNewFileResolved(false)
    const nameInvalid = isFileNameInvalid(nameInput)
    if (nameInvalid) {
      setError(nameInvalid)
    } else {
      try {
        var newFileParams = {
          type: FileManagementActions.NEW_FILE,
          parentFolder,
          name: nameInput,
        }
        if (saveAs){
          newFileParams.code = codePanelState.editorInstance.getModel().getValue()
        }
        const fileUid = await fileManagementDispatch(newFileParams)
        if (componentMounted.current) {
          if (saveAs){
            await dispatch({ type: CodePanelActions.CLOSE_TABS, fileUids: [codePanelState.tabs.focused] })
          }
          await dispatch({ type: CodePanelActions.OPEN_TAB, fileUid })
          handleClose()
        }
      } catch (err) {
        // console.error(err)
        setError({type: 'exists', fileName: nameInput})
      }
    }
    setNewFileResolved(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, nameInput, parentFolder, newFileResolved])

  React.useEffect(() => {
    // When the user closes an error message, refocus the text field
    if (textInputRef.current && !error){
      textInputRef.current.focus()
    }
  }, [error])

  React.useEffect(() => {
    // If the mode is save as, then name input becomes focused file name,
    // and get selected when the file name is changed. Then, since we're
    // keeping the modal mounted, focus the modal text box on open.
    if (textInputRef.current){
      if (saveAs){
        if (copy) {
          const sharedFileMetaData = readSharedFileFromRepo(codePanelState.tabs.focused)
          setNameInput(sharedFileMetaData.name)
        } else {
          setNameInput(codePanelState.filename)
        }
        selectText.current = true
      }
      textInputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  React.useEffect(() => {
    // If the mode is save as, and we haven't highlighted the selected text yet,
    // highlight the selected text.
    if (saveAs && textInputRef.current && selectText.current){
      selectText.current = false
      textInputRef.current.select()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameInput])

  React.useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <div>
      <Modal
        open={open}
        onClose={(ev, reason) => {
          if (reason === 'backdropClick' ){
            return
          }
          handleClose()
        }}
        className={classes.modal}
        style={{height:'70%'}}
        keepMounted
      >
        <Paper className={classes.paper} style={{width:500, height:160}}>
          <div className={classes.modalContent}>
            <div style={{width:'95%'}}>
              {copy ? 'Make A Copy' : create ? 'New File' : 'Save As'}
              <Divider />
            </div>
            <div className={classes.textField}>
              <OutlinedTextField
                inputRef={textInputRef}
                fullWidth
                label={'File Name'}
                value={nameInput}
                onKeyDown={async (ev)=>{
                  if (ev.key === 'Enter' && newFileResolved && open){
                    await newFile()
                  }
                }}
                onChange={ev=>(setNameInput(ev.target.value))}
                InputProps={{ spellCheck: 'false' }}
                autoComplete='off'
              />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', gap:10, width:'95%'}}>
              {!newFileResolved ? <CircularProgress size={15} className={classes.circularProgress} style={{marginBottom: 5}}/>:null}
              <WindowsButton
                onClick={newFile}
                disabled={!newFileResolved}
                label={create ? 'Create' : 'Save'}
              />
              <WindowsButton
                onClick={handleClose}
                label={'Cancel'}
              />
            </div>
          </div>
        </Paper>
      </Modal>
      <ErrorModal error={error} open={Boolean(error)} onClose={()=>setError(null)}/>
    </div>
  )
}

export { NewFileModal, FilePropertiesModal, ConfirmDeleteFileModal, WindowsButton, ShareFileModal }