import { hterm } from './utils/hterm_all'

// TODO Factor out WebConsole from WebTerminal
export class WebTerminal {
  constructor() {
    this.preInitBuf = ''
    this.terminalIO = {
      print: (text) => {
        this.preInitBuf += text
      },
      println: (text) => {
        this.preInitBuf += text + '\n'
      },
    } // will get filled in by onTerminalReady()

    this.terminalObj = undefined

    // Default to playing well with Python, which follows the end=LF paradigm
    this.autoCR = true

    this.insertMode = true

    this.textDestination = undefined
    this.historyBuffer = [] // Choosing to treat historyBuffer[0] as the most recently executed command
    this.historyBufferIndex = 0 // Must also take into account historyBuffer.length
    this.eatOneUpKey = true
    this.lineBuffer = ''
    this.lineBufferIndex = 0

    // Note that these are specific to the terminal that we are using
    // TODO Refactor this to use passed-in "key maps"of some sort
    this.KEY_TAB = '\x09'
    this.KEY_ESC = '\x1b'
    this.KEY_CR = '\r'
    this.KEY_DEL = '\x1b\x5b\x33\x7e'
    this.KEY_BS = '\x7F'
    this.KEY_LEFT = '\x1b\x5b\x44'
    this.KEY_SHIFT_LEFT = '\x1b\x5b\x31\x3b\x32\x44'
    this.KEY_CTRL_LEFT = '\x1b\x5b\x31\x3b\x35\x44'
    this.KEY_CTRL_SHIFT_LEFT = '\x1b\x5b\x31\x3b\x36\x44'
    this.KEY_ALT_LEFT = '\x1b\x5b\x31\x3b\x33\x44'
    this.KEY_ALT_SHIFT_LEFT = '\x1b\x5b\x31\x3b\x34\x44'
    this.KEY_ALT_CTRL_LEFT = '\x1b\x5b\x31\x3b\x37\x44'
    this.KEY_ALT_CTRL_SHIFT_LEFT = '\x1b\x5b\x31\x3b\x38\x44'
    this.KEY_RIGHT = '\x1b\x5b\x43'
    this.KEY_SHIFT_RIGHT = '\x1b\x5b\x31\x3b\x32\x43'
    this.KEY_CTRL_RIGHT = '\x1b\x5b\x31\x3b\x35\x43'
    this.KEY_CTRL_SHIFT_RIGHT = '\x1b\x5b\x31\x3b\x36\x43'
    this.KEY_ALT_RIGHT = '\x1b\x5b\x31\x3b\x33\x43'
    this.KEY_ALT_SHIFT_RIGHT = '\x1b\x5b\x31\x3b\x34\x43'
    this.KEY_ALT_CTRL_RIGHT = '\x1b\x5b\x31\x3b\x37\x43'
    this.KEY_ALT_CTRL_SHIFT_RIGHT = '\x1b\x5b\x31\x3b\x38\x43'
    this.KEY_UP = '\x1b\x5b\x41'
    // this.KEY_SHIFT_UP = ; // Does not come through with current terminal emulator
    this.KEY_CTRL_UP = '\x1b\x5b\x31\x3b\x35\x41'
    // this.KEY_CTRL_SHIFT_UP = ; // Does not come through with current terminal emulator
    this.KEY_ALT_UP = '\x1b\x5b\x31\x3b\x33\x41'
    // this.KEY_ALT_SHIFT_UP = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_UP = '\x1b\x5b\x31\x3b\x37\x41'
    // this.KEY_ALT_CTRL_SHIFT_UP = ; // Does not come through with current terminal emulator
    this.KEY_DOWN = '\x1b\x5b\x42'
    // this.KEY_SHIFT_DOWN = ; // Does not come through with current terminal emulator
    this.KEY_CTRL_DOWN = '\x1b\x5b\x31\x3b\x35\x42'
    // this.KEY_CTRL_SHIFT_DOWN = ; // Does not come through with current terminal emulator
    this.KEY_ALT_DOWN = '\x1b\x5b\x31\x3b\x33\x42'
    // this.KEY_ALT_SHIFT_DOWN = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_DOWN = '\x1b\x5b\x31\x3b\x37\x42'
    // this.KEY_ALT_CTRL_SHIFT_DOWN = ; // Does not come through with current terminal emulator
    this.KEY_HOME = '\x1b\x5b\x48'
    // this.KEY_SHIFT_HOME = ; // Does not come through with current terminal emulator
    this.KEY_CTRL_HOME = '\x1b\x5b\x31\x3b\x35\x48'
    // this.KEY_CTRL_SHIFT_HOME = ; // Does not come through with current terminal emulator
    this.KEY_ALT_HOME = '\x1b\x5b\x31\x3b\x33\x48'
    // this.KEY_ALT_SHIFT_HOME = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_HOME = '\x1b\x5b\x31\x3b\x37\x48'
    // this.KEY_ALT_CTRL_SHIFT_HOME = ; // Does not come through with current terminal emulator
    this.KEY_END = '\x1b\x5b\x46'
    // this.KEY_SHIFT_END = ; // Does not come through with current terminal emulator
    this.KEY_CTRL_END = '\x1b\x5b\x31\x3b\x35\x46'
    // this.KEY_CTRL_SHIFT_END = ; // Does not come through with current terminal emulator
    this.KEY_ALT_END = '\x1b\x5b\x31\x3b\x33\x46'
    // this.KEY_ALT_SHIFT_END = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_END = '\x1b\x5b\x31\x3b\x37\x46'
    // this.KEY_ALT_CTRL_SHIFT_END = ; // Does not come through with current terminal emulator
    this.KEY_INS = '\x1b\x5b\x32\x7e'
    // this.KEY_SHIFT_INS = ; // This is a live PASTE command on my computer
    this.KEY_CTRL_INS = '\x1b\x5b\x32\x3b\x35\x7e'
    // this.KEY_CTRL_SHIFT_INS = ; // Does not come through with current terminal emulator
    this.KEY_ALT_INS = '\x1b\x5b\x32\x3b\x33\x7e'
    // this.KEY_ALT_SHIFT_INS = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_INS = '\x1b\x5b\x32\x3b\x37\x7e'
    // this.KEY_ALT_CTRL_SHIFT_INS = ; // Does not come through with current terminal emulator
    this.KEY_PGUP = '\x1b\x5b\x35\x7e'
    // this.KEY_SHIFT_PGUP = ; // Does not come through with current terminal emulator
    // this.KEY_CTRL_PGUP = ; // Changes active tab, goes to the left!
    // this.KEY_CTRL_SHIFT_PGUP = ; // Does not come through with current terminal emulator
    this.KEY_ALT_PGUP = '\x1b\x5b\x35\x3b\x33\x7e'
    // this.KEY_ALT_SHIFT_PGUP = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_PGUP = '\x1b\x5b\x35\x3b\x37\x7e'
    // this.KEY_ALT_CTRL_SHIFT_PGUP = ; // Does not come through with current terminal emulator
    this.KEY_PGDN = '\x1b\x5b\x36\x7e'
    // this.KEY_SHIFT_PGDN = ; // Does not come through with current terminal emulator
    // this.KEY_CTRL_PGDN = ; // Changes active tab, goes to the right!
    // this.KEY_CTRL_SHIFT_PGDN = ; // Does not come through with current terminal emulator
    this.KEY_ALT_PGDN = '\x1b\x5b\x36\x3b\x33\x7e'
    // this.KEY_ALT_SHIFT_PGDN = ; // Does not come through with current terminal emulator
    this.KEY_ALT_CTRL_PGDN = '\x1b\x5b\x36\x3b\x37\x7e'
    // this.KEY_ALT_CTRL_SHIFT_PGDN = ; // Does not come through with current terminal emulator
    this.KEY_F1 = '\x1b\x4f\x50'
    this.KEY_SHIFT_F1 = '\x1b\x5b\x31\x3b\x32\x50'
    this.KEY_CTRL_F1 = '\x1b\x5b\x31\x3b\x35\x50'
    this.KEY_CTRL_SHIFT_F1 = '\x1b\x5b\x31\x3b\x36\x50'
    this.KEY_ALT_F1 = '\x1b\x5b\x32\x33\x7e'
    this.KEY_ALT_SHIFT_F1 = '\x1b\x5b\x32\x33\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F1 = '\x1b\x5b\x31\x3b\x37\x50'
    this.KEY_ALT_CTRL_SHIFT_F1 = '\x1b\x5b\x31\x3b\x38\x50'
    this.KEY_F2 = '\x1b\x4f\x51'
    this.KEY_SHIFT_F2 = '\x1b\x5b\x31\x3b\x32\x51'
    this.KEY_CTRL_F2 = '\x1b\x5b\x31\x3b\x35\x51'
    this.KEY_CTRL_SHIFT_F2 = '\x1b\x5b\x31\x3b\x36\x51'
    this.KEY_ALT_F2 = '\x1b\x5b\x32\x34\x7e'
    this.KEY_ALT_SHIFT_F2 = '\x1b\x5b\x32\x34\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F2 = '\x1b\x5b\x31\x3b\x37\x51'
    this.KEY_ALT_CTRL_SHIFT_F2 = '\x1b\x5b\x31\x3b\x38\x51'
    this.KEY_F3 = '\x1b\x4f\x52'
    this.KEY_SHIFT_F3 = '\x1b\x5b\x31\x3b\x32\x52'
    this.KEY_CTRL_F3 = '\x1b\x5b\x31\x3b\x35\x52'
    this.KEY_CTRL_SHIFT_F3 = '\x1b\x5b\x31\x3b\x36\x52'
    this.KEY_ALT_F3 = '\x1b\x5b\x32\x35\x7e'
    this.KEY_ALT_SHIFT_F3 = '\x1b\x5b\x32\x35\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F3 = '\x1b\x5b\x31\x3b\x37\x52'
    this.KEY_ALT_CTRL_SHIFT_F3 = '\x1b\x5b\x31\x3b\x38\x52'
    // Some F4 combinations get intercepted by Windows
    // I am GUESSING that they will follow the pattern on other platforms
    this.KEY_F4 = '\x1b\x4f\x53'
    this.KEY_SHIFT_F4 = '\x1b\x5b\x31\x3b\x32\x53'
    this.KEY_CTRL_F4 = '\x1b\x5b\x31\x3b\x35\x53' // this is a GUESS - CTRL-F4 exits browser under Windows
    this.KEY_CTRL_SHIFT_F4 = '\x1b\x5b\x31\x3b\x36\x53'
    this.KEY_ALT_F4 = '\x1b\x5b\x32\x36\x7e' // this is a GUESS - ALT-F4 exits browser under Windows
    this.KEY_ALT_SHIFT_F4 = '\x1b\x5b\x32\x36\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F4 = '\x1b\x5b\x31\x3b\x37\x53'
    this.KEY_ALT_CTRL_SHIFT_F4 = '\x1b\x5b\x31\x3b\x38\x53'
    this.KEY_F5 = '\x1b\x5b\x31\x35\x7e'
    this.KEY_SHIFT_F5 = '\x1b\x5b\x31\x35\x3b\x32\x7e'
    this.KEY_CTRL_F5 = '\x1b\x5b\x31\x35\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F5 = '\x1b\x5b\x31\x35\x3b\x36\x7e'
    this.KEY_ALT_F5 = '\x1b\x5b\x32\x38\x7e'
    this.KEY_ALT_SHIFT_F5 = '\x1b\x5b\x32\x38\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F5 = '\x1b\x5b\x31\x35\x3b\x37\x7e'
    this.KEY_ALT_CTRL_SHIFT_F5 = '\x1b\x5b\x31\x35\x3b\x38\x7e'
    this.KEY_F6 = '\x1b\x5b\x31\x37\x7e'
    this.KEY_SHIFT_F6 = '\x1b\x5b\x31\x37\x3b\x32\x7e'
    this.KEY_CTRL_F6 = '\x1b\x5b\x31\x37\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F6 = '\x1b\x5b\x31\x37\x3b\x36\x7e'
    this.KEY_ALT_F6 = '\x1b\x5b\x32\x39\x7e'
    this.KEY_ALT_SHIFT_F6 = '\x1b\x5b\x32\x39\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F6 = '\x1b\x5b\x31\x37\x3b\x37\x7e'
    this.KEY_ALT_CTRL_SHIFT_F6 = '\x1b\x5b\x31\x37\x3b\x38\x7e'
    this.KEY_F7 = '\x1b\x5b\x31\x38\x7e'
    this.KEY_SHIFT_F7 = '\x1b\x5b\x31\x38\x3b\x32\x7e'
    this.KEY_CTRL_F7 = '\x1b\x5b\x31\x38\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F7 = '\x1b\x5b\x31\x38\x3b\x36\x7e'
    this.KEY_ALT_F7 = '\x1b\x5b\x33\x31\x7e'
    this.KEY_ALT_SHIFT_F7 = '\x1b\x5b\x33\x31\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F7 = '\x1b\x5b\x31\x38\x3b\x37\x7e'
    this.KEY_ALT_CTRL_SHIFT_F7 = '\x1b\x5b\x31\x38\x3b\x38\x7e'
    this.KEY_F8 = '\x1b\x5b\x31\x39\x7e'
    this.KEY_SHIFT_F8 = '\x1b\x5b\x31\x39\x3b\x32\x7e'
    this.KEY_CTRL_F8 = '\x1b\x5b\x31\x39\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F8 = '\x1b\x5b\x31\x39\x3b\x36\x7e'
    this.KEY_ALT_F8 = '\x1b\x5b\x33\x32\x7e'
    this.KEY_ALT_SHIFT_F8 = '\x1b\x5b\x33\x32\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F8 = '\x1b\x5b\x31\x39\x3b\x37\x7e' // This is a GUESS - this keystroke filtered under Windows
    this.KEY_ALT_CTRL_SHIFT_F8 = '\x1b\x5b\x31\x39\x3b\x38\x7e'
    this.KEY_F9 = '\x1b\x5b\x32\x30\x7e'
    this.KEY_SHIFT_F9 = '\x1b\x5b\x32\x30\x3b\x32\x7e'
    this.KEY_CTRL_F9 = '\x1b\x5b\x32\x30\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F9 = '\x1b\x5b\x32\x30\x3b\x36\x7e'
    this.KEY_ALT_F9 = '\x1b\x5b\x33\x33\x7e'
    this.KEY_ALT_SHIFT_F9 = '\x1b\x5b\x33\x33\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F9 = '\x1b\x5b\x32\x30\x3b\x37\x7e'
    this.KEY_ALT_CTRL_SHIFT_F9 = '\x1b\x5b\x32\x30\x3b\x38\x7e'
    this.KEY_F10 = '\x1b\x5b\x32\x31\x7e'
    this.KEY_SHIFT_F10 = '\x1b\x5b\x32\x31\x3b\x32\x7e'
    this.KEY_CTRL_F10 = '\x1b\x5b\x32\x31\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F10 = '\x1b\x5b\x32\x31\x3b\x36\x7e'
    this.KEY_ALT_F10 = '\x1b\x5b\x33\x34\x7e'
    this.KEY_ALT_SHIFT_F10 = '\x1b\x5b\x33\x34\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F10 = '\x1b\x5b\x32\x31\x3b\x37\x7e'
    this.KEY_ALT_CTRL_SHIFT_F10 = '\x1b\x5b\x32\x31\x3b\x38\x7e'
    // Several of the F11 key combinations are weird...
    // Many are filtered under Windows (making me guess)
    // Also under Windows at least, Shift-F11 == Alt-Shift-F1
    this.KEY_F11 = '\x1b\x5b\x32\x33\x7e' // GUESS! Filtered under Windows
    this.KEY_SHIFT_F11 = '\x1b\x5b\x32\x33\x3b\x32\x7e' // NOTE! Duplicates Alt-Shift-F1 under Windows
    this.KEY_CTRL_F11 = '\x1b\x5b\x32\x33\x3b\x35\x7e' // GUESS! Filtered under Windows
    this.KEY_CTRL_SHIFT_F11 = '\x1b\x5b\x32\x33\x3b\x36\x7e'
    this.KEY_ALT_F11 = '\x1b\x5b\x34\x32\x7e'
    this.KEY_ALT_SHIFT_F11 = '\x1b\x5b\x34\x32\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F11 = '\x1b\x5b\x32\x33\x3b\x37\x7e' // GUESS! Filtered under Windows
    this.KEY_ALT_CTRL_SHIFT_F11 = '\x1b\x5b\x32\x33\x3b\x38\x7e'
    // Also have issues with the F12 series:
    // F12 duplicates Alt-F2
    // Shift-F12 duplicate Alt-Shift-F2
    // Alt-Ctrl-F12 brings up a graphics control utility on my PC, will have to guess
    this.KEY_F12 = '\x1b\x5b\x32\x34\x7e' // Duplicate of Alt-F2
    this.KEY_SHIFT_F12 = '\x1b\x5b\x32\x34\x3b\x32\x7e' // Duplicate of Alt-Shift-F2
    this.KEY_CTRL_F12 = '\x1b\x5b\x32\x34\x3b\x35\x7e'
    this.KEY_CTRL_SHIFT_F12 = '\x1b\x5b\x32\x34\x3b\x36\x7e'
    this.KEY_ALT_F12 = '\x1b\x5b\x34\x33\x7e'
    this.KEY_ALT_SHIFT_F12 = '\x1b\x5b\x34\x33\x3b\x32\x7e'
    this.KEY_ALT_CTRL_F12 = '\x1b\x5b\x32\x34\x3b\x37\x7e' // GUESS! Filtered under Windows
    this.KEY_ALT_CTRL_SHIFT_F12 = '\x1b\x5b\x32\x34\x3b\x38\x7e'
    this.KEY_CTRL_C = '\x03'

    // TODO Same issue here as above
    this.BELL = '\u0007'
    this.BS = '\x08'
    this.ERASE_EOL = '\x1b\x5b\x4b'
    this.CURSOR_LEFT = '\x1b\x5b\x44' // see also moveCursorLeftN
    this.CURSOR_RIGHT = '\x1b\x5b\x43' // see also moveCursorRightN

    this.CURSOR_BLINKING_BLOCK = '\x1b\x5b\x31\x20\x71'
    this.CURSOR_SOLID_BLOCK = '\x1b\x5b\x32\x20\x71'
    this.CURSOR_BLINKING_UNDERSCORE = '\x1b\x5b\x33\x20\x71'
    this.CURSOR_SOLID_UNDERSCORE = '\x1b\x5b\x34\x20\x71'
    this.CURSOR_BLINKING_VERTICLE = '\x1b\x5b\x35\x20\x71'
    this.CURSOR_SOLID_VERTICLE = '\x1b\x5b\x36\x20\x71'

    this.DISALLOWED_KEYS = [
      this.KEY_F1, this.KEY_SHIFT_F1, this.KEY_CTRL_F1, this.KEY_CTRL_SHIFT_F1,
      this.KEY_ALT_F1, this.KEY_ALT_SHIFT_F1, this.KEY_ALT_CTRL_F1, this.KEY_ALT_CTRL_SHIFT_F1,
      this.KEY_F2, this.KEY_SHIFT_F2, this.KEY_CTRL_F2, this.KEY_CTRL_SHIFT_F2,
      this.KEY_ALT_F2, this.KEY_ALT_SHIFT_F2, this.KEY_ALT_CTRL_F2, this.KEY_ALT_CTRL_SHIFT_F2,
      this.KEY_F3, this.KEY_SHIFT_F3, this.KEY_CTRL_F3, this.KEY_CTRL_SHIFT_F3,
      this.KEY_ALT_F3, this.KEY_ALT_SHIFT_F3, this.KEY_ALT_CTRL_F3, this.KEY_ALT_CTRL_SHIFT_F3,
      this.KEY_F4, this.KEY_SHIFT_F4, this.KEY_CTRL_F4, this.KEY_CTRL_SHIFT_F4,
      this.KEY_ALT_F4, this.KEY_ALT_SHIFT_F4, this.KEY_ALT_CTRL_F4, this.KEY_ALT_CTRL_SHIFT_F4,
      this.KEY_F5, this.KEY_SHIFT_F5, this.KEY_CTRL_F5, this.KEY_CTRL_SHIFT_F5,
      this.KEY_ALT_F5, this.KEY_ALT_SHIFT_F5, this.KEY_ALT_CTRL_F5, this.KEY_ALT_CTRL_SHIFT_F5,
      this.KEY_F6, this.KEY_SHIFT_F6, this.KEY_CTRL_F6, this.KEY_CTRL_SHIFT_F6,
      this.KEY_ALT_F6, this.KEY_ALT_SHIFT_F6, this.KEY_ALT_CTRL_F6, this.KEY_ALT_CTRL_SHIFT_F6,
      this.KEY_F7, this.KEY_SHIFT_F7, this.KEY_CTRL_F7, this.KEY_CTRL_SHIFT_F7,
      this.KEY_ALT_F7, this.KEY_ALT_SHIFT_F7, this.KEY_ALT_CTRL_F7, this.KEY_ALT_CTRL_SHIFT_F7,
      this.KEY_F8, this.KEY_SHIFT_F8, this.KEY_CTRL_F8, this.KEY_CTRL_SHIFT_F8,
      this.KEY_ALT_F8, this.KEY_ALT_SHIFT_F8, this.KEY_ALT_CTRL_F8, this.KEY_ALT_CTRL_SHIFT_F8,
      this.KEY_F9, this.KEY_SHIFT_F9, this.KEY_CTRL_F9, this.KEY_CTRL_SHIFT_F9,
      this.KEY_ALT_F9, this.KEY_ALT_SHIFT_F9, this.KEY_ALT_CTRL_F9, this.KEY_ALT_CTRL_SHIFT_F9,
      this.KEY_F10, this.KEY_SHIFT_F10, this.KEY_CTRL_F10, this.KEY_CTRL_SHIFT_F10,
      this.KEY_ALT_F10, this.KEY_ALT_SHIFT_F10, this.KEY_ALT_CTRL_F10, this.KEY_ALT_CTRL_SHIFT_F10,
      this.KEY_F11, this.KEY_SHIFT_F11, this.KEY_CTRL_F11, this.KEY_CTRL_SHIFT_F11,
      this.KEY_ALT_F11, this.KEY_ALT_SHIFT_F11, this.KEY_ALT_CTRL_F11, this.KEY_ALT_CTRL_SHIFT_F11,
      this.KEY_F12, this.KEY_SHIFT_F12, this.KEY_CTRL_F12, this.KEY_CTRL_SHIFT_F12,
      this.KEY_ALT_F12, this.KEY_ALT_SHIFT_F12, this.KEY_ALT_CTRL_F12, this.KEY_ALT_CTRL_SHIFT_F12,
      this.KEY_CTRL_UP, this.KEY_ALT_UP, this.KEY_ALT_CTRL_UP,
      this.KEY_CTRL_DOWN, this.KEY_ALT_DOWN, this.KEY_ALT_CTRL_DOWN,
      this.KEY_SHIFT_LEFT, this.KEY_CTRL_LEFT, this.KEY_CTRL_SHIFT_LEFT,
      this.KEY_ALT_LEFT, this.KEY_ALT_SHIFT_LEFT, this.KEY_ALT_CTRL_LEFT, this.KEY_ALT_CTRL_SHIFT_LEFT,
      this.KEY_SHIFT_RIGHT, this.KEY_CTRL_RIGHT, this.KEY_CTRL_SHIFT_RIGHT,
      this.KEY_ALT_RIGHT, this.KEY_ALT_SHIFT_RIGHT, this.KEY_ALT_CTRL_RIGHT, this.KEY_ALT_CTRL_SHIFT_RIGHT,
      this.KEY_CTRL_INS, this.KEY_ALT_INS, this.KEY_ALT_CTRL_INS,
      this.KEY_ALT_PGUP, this.KEY_ALT_CTRL_PGUP,
      this.KEY_ALT_PGDN, this.KEY_ALT_CTRL_PGDN,
      this.KEY_CTRL_HOME, this.KEY_ALT_HOME, this.KEY_ALT_CTRL_HOME,
      this.KEY_CTRL_END, this.KEY_ALT_END, this.KEY_ALT_CTRL_END,
    ]
  }

  init = async (domElementName) => {
    // The hterm implementation splits the functionality across
    // a parent Terminal object and a child io object
    this.terminalObj = new hterm.Terminal()

    // Add message definitions to avoid console warnings
    hterm.messageManager.addMessages({
      'HTERM_BUTTON_CLOSE': {message: 'Close'},
      'HTERM_BUTTON_FIND': {message: 'Find'},
      'HTERM_BUTTON_NEXT': {message: 'Next'},
      'HTERM_BUTTON_PAGE_DOWN': {message: 'Page down'},
      'HTERM_BUTTON_PAGE_UP': {message: 'Page up'},
      'HTERM_BUTTON_PREVIOUS': {message: 'Previous'},
      'HTERM_OPTIONS_BUTTON_LABEL': {message: 'Label'},
    })

    const target = document.getElementById(domElementName)
    if (!target) {
      console.error('WebTerminal instantiated with invalid DOM Element')
      return
    }
    this.terminalObj.onTerminalReady = this.onTerminalReady
    await this.terminalObj.decorate(target)
    this.terminalObj.installKeyboard()
    this.terminalObj.contextMenu.setItems([
      // {name: 'Terminal Reset', action: () => this.resetTerminal()},
      {name: 'Terminal Clear', action: () => this.resetTerminal()},
      {name: 'Paste', action: () => this.paste()},
      // {name: hterm.ContextMenu.SEPARATOR},
    ])
  }

  paste = () => {
    navigator.clipboard.readText().then((text) => {
      // Clear the selection before pasting
      try {
        var sel = this.terminalIO?.terminal_.document_.getSelection()
        if (sel) {
          if (sel.removeAllRanges) {
            sel.removeAllRanges()
          } else if (sel.empty) {
            sel.empty()
          }
        }
      } catch {
        //
      }

      this.onIncomingCharacters(text)
    })
  }

  clearTerminal = () => {
    if (this.terminalObj !== undefined) {
      this.terminalObj.wipeContents()  // Clear the terminal and scrollback buffer
    }
    this.lineBuffer = ''
    this.lineBufferIndex = 0
    this.setInsertMode()
  }

  resetTerminal = () => {
    if (this.terminalObj !== undefined) {
      this.terminalObj.reset()
    }
    this.historyBuffer = []
    this.historyBufferIndex = 0
    this.eatOneUpKey = true
    this.clearTerminal()
  }

  moveCursorLeftN = (n) => {
    if (n <= 0) {
      return
    }
    this.terminalIO.print('\x1b\x5b' + n + '\x44')
  }

  moveCursorRightN = (n) => {
    if (n <= 0) {
      return
    }
    this.terminalIO.print('\x1b\x5b' + n + '\x43')
  }

  setTextDestination = (dest) => {
    this.textDestination = dest
  }

  // Keep the option of having full (manual) CR/LF control
  setAutoCR = (val) => {
    this.autoCR = val
  }

  setInsertMode = () => {
    this.insertMode = true
    this.terminalIO.print(this.CURSOR_BLINKING_UNDERSCORE)
  }

  setOverwriteMode = () => {
    this.insertMode = false
    this.terminalIO.print(this.CURSOR_BLINKING_BLOCK)
  }

  toggleInsertMode = () => {
    if (this.insertMode) {
      this.setOverwriteMode()
    } else {
      this.setInsertMode()
    }
  }

  pullFromHistoryBuffer = () => {
    this.moveCursorLeftN(this.lineBufferIndex)
    this.lineBuffer = this.historyBuffer[this.historyBufferIndex]
    this.lineBufferIndex = this.lineBuffer.length
    this.terminalIO.print(this.ERASE_EOL + this.lineBuffer)
    this.setInsertMode()
    this.eatOneUpKey = false
  }

  onIncomingCharacters = (string) => {
    var firstPart
    var secondPart
    // Use this to figure out what various keys are coming in as
    // this.terminalIO.print(''+string.length+': ')
    // var count = string.length
    // var index = 0
    // while (index < count) {
    //  var code = string.charCodeAt(index)
    //  this.terminalIO.print(code.toString(16) + ' ')
    //  index += 1
    // }
    // Use this to get the same info in a copy/paste-ready format
    // this.terminalIO.print(''+string.length+': ')
    // var count = string.length
    // var index = 0
    // this.terminalIO.print("'")
    // while (index < count) {
    //  var code = string.charCodeAt(index)
    //  this.terminalIO.print('\\x' + code.toString(16))
    //  index += 1
    // }
    // this.terminalIO.print("'")

    if (string === this.KEY_CTRL_C) {
      if (this.textDestination) {
        this.textDestination(string)
      }
      return
    }

    if (this.DISALLOWED_KEYS.includes(string)) {
      this.terminalIO.print(this.BELL)
      return
    }

    // Implement "tab stops"
    if (string === this.KEY_TAB) {
      string = ' '.repeat(4 - (this.lineBufferIndex % 4))
    }

    switch (string) {
      case this.KEY_LEFT:
        if (this.lineBufferIndex > 0) {
          this.lineBufferIndex -= 1
          this.moveCursorLeftN(1)
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_HOME:
        if (this.lineBufferIndex > 0) {
          this.moveCursorLeftN(this.lineBufferIndex)
          this.lineBufferIndex = 0
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_RIGHT:
        if (this.lineBufferIndex < this.lineBuffer.length) {
          this.lineBufferIndex += 1
          this.moveCursorRightN(1)
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_END:
        if (this.lineBufferIndex < this.lineBuffer.length) {
          this.moveCursorRightN(this.lineBuffer.length - this.lineBufferIndex)
          this.lineBufferIndex = this.lineBuffer.length
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_DEL:
        if (this.lineBufferIndex < this.lineBuffer.length) {
          firstPart = this.lineBuffer.slice(0, this.lineBufferIndex)
          secondPart = (this.lineBufferIndex >= this.lineBuffer_length) ? '' : this.lineBuffer.slice(this.lineBufferIndex + 1)
          this.terminalIO.print(secondPart + this.ERASE_EOL)
          this.moveCursorLeftN(secondPart.length)
          this.lineBuffer = firstPart + secondPart
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_BS:
        if ((this.lineBuffer.length > 0) && (this.lineBufferIndex > 0)) {
          firstPart = this.lineBuffer.slice(0, this.lineBufferIndex - 1)
          secondPart = (this.lineBufferIndex >= this.lineBuffer_length) ? '' : this.lineBuffer.slice(this.lineBufferIndex)
          this.terminalIO.print(this.BS + secondPart + this.ERASE_EOL)
          this.moveCursorLeftN(secondPart.length)
          this.lineBuffer = firstPart + secondPart
          this.lineBufferIndex -= 1
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_CR:
        this.terminalIO.println('')
        if (this.textDestination) {
          this.textDestination(this.lineBuffer)
        }
        // Don't bother putting empty commands into the history buffer
        if (this.lineBuffer.length > 0) {
          // First entry into the history buffer does not require special checks
          if (this.historyBuffer.length === 0) {
            this.historyBuffer.unshift(this.lineBuffer)
          } else {
            // Don't fill the history buffer up with repeated commands
            if (this.historyBuffer[0] !== this.lineBuffer) {
              // We are optimizing for repeating commands and repeating command sequences
              // Was history used without alteration?
              if (this.lineBuffer === this.historyBuffer[this.historyBufferIndex]) {
                // History was used as-is: keep us positioned to do more of the same
                this.historyBufferIndex += 1 // account for the upcoming shift in the history data
              } else {
                // History was NOT used as-is: position us to potentially re-use the brand new command
                this.historyBufferIndex = 0
              }
              this.historyBuffer.unshift(this.lineBuffer)
            }
          }
        }
        this.lineBuffer = ''
        this.lineBufferIndex = 0
        this.setInsertMode()
        this.eatOneUpKey = true
        break
      case this.KEY_INS:
        this.toggleInsertMode()
        break
      case this.KEY_UP:
        if (this.historyBuffer.length > 0) {
          if (this.eatOneUpKey) {
            this.historyBufferIndex -= 1
            this.eatOneUpKey = false
          }
          if ((this.historyBufferIndex + 1) < this.historyBuffer.length) {
            this.historyBufferIndex += 1
            this.pullFromHistoryBuffer()
          } else {
            this.terminalIO.print(this.BELL)
          }
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_DOWN:
        if (this.historyBuffer.length > 0) {
          if ((this.historyBufferIndex - 1) >= 0) {
            this.historyBufferIndex -= 1
            this.pullFromHistoryBuffer()
          } else {
            this.terminalIO.print(this.BELL)
          }
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_PGUP:
        if (this.historyBuffer.length > 0) {
          if (this.historyBufferIndex < (this.historyBuffer.length - 1)) {
            this.historyBufferIndex = this.historyBuffer.length - 1
            this.pullFromHistoryBuffer()
          } else {
            this.terminalIO.print(this.BELL)
          }
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_PGDN:
        if (this.historyBuffer.length > 0) {
          if (this.historyBufferIndex > 0) {
            this.historyBufferIndex = 0
            this.pullFromHistoryBuffer()
          } else {
            this.terminalIO.print(this.BELL)
          }
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      case this.KEY_ESC:
        if (this.lineBuffer.length > 0) {
          this.moveCursorLeftN(this.lineBufferIndex)
          this.terminalIO.print(this.ERASE_EOL)
          this.lineBuffer = ''
          this.lineBufferIndex = 0
          this.setInsertMode()
        } else {
          this.terminalIO.print(this.BELL)
        }
        break
      default:
        // Handle the simple case quickly
        if (this.lineBufferIndex === this.lineBuffer.length) {
          this.lineBuffer += string
          this.lineBufferIndex += string.length
          this.terminalIO.print(string)
        } else { // deal with edits in the middle of the line
          var offset = this.insertMode ? 0 : 1
          firstPart = this.lineBuffer.slice(0, this.lineBufferIndex)
          secondPart = this.lineBuffer.slice(this.lineBufferIndex + offset)
          this.terminalIO.print(string + secondPart)
          this.moveCursorLeftN(secondPart.length)
          this.lineBuffer = firstPart + string + secondPart
          this.lineBufferIndex += string.length
        }
        break
    }
  }

  scrollEnd = () => {
    if (this.terminalObj !== undefined) {
      this.terminalObj.scrollEnd()
    }
  }

  onOutgoingCharacters = (string) => {
    if (this.autoCR) {
      var correctedString = ''
      var count = string.length
      var index = 0
      var previousChar = ''
      while (index < count) {
        var char = string[index]
        if ((char === '\n') && (previousChar !== '\r')) {
          correctedString += '\r'
        }
        correctedString += char
        previousChar = char
        index += 1
      }
      this.terminalIO.print(correctedString)
    } else {
      this.terminalIO.print(string)
    }
  }

  onTerminalReady = () => {
    this.terminalIO = this.terminalObj.io.push()

    this.terminalIO.onVTKeystroke = this.onIncomingCharacters
    this.terminalIO.sendString = this.terminalIO.print

    // this.terminalIO.println('This is a virtual console for your Python code to use')
    // this.terminalIO.println('(print(), input(), etc.)')
    // Changed the startup banner when we started auto-launching the REPL
    // this.terminalIO.print('>>> ')
    this.terminalObj.setCursorVisible(true)
    this.setInsertMode()
    this.terminalIO.print(this.preInitBuf)
    this.preInitBuf = ''
  }
}
