/* FiriaMarkdown - A react markdown component with selected / custom plugins
   Notes:
     * Be sure the markdown string you pass in doesn't have leading spaces.

    Using react-markdown, which has good plugin capabilities:
      https://github.com/rexxars/react-markdown

    Using react-syntax-highlighter, which has good plugin capabilities:
      https://www.npmjs.com/package/react-syntax-highlighter
      * It also allows choice of highlighting engine. Currently using Prism.


*/
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'
import RemarkMathPlugin from 'remark-math'
import RemarkFootnotesPlugin from 'remark-footnotes'
import { BlockMath, InlineMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import JsxParser from 'react-jsx-parser'
import BuildIcon from '@material-ui/icons/Build'
import { useMission, MissionActions } from './contexts/MissionContext'
import Button from '@material-ui/core/Button'
import LaunchIcon from '@material-ui/icons/Launch'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'
import { codeMarkerController } from './CodeMarkerControl'
import { isOwnedTool } from './Toolbox/ToolboxUtil'
import { cacheBust } from './firebase/domain'
import { IconButton, Tooltip } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopy'
import fetchHandler from './tools/tracking/fetchHandler'
import { LESSON_SECTION_PANELS, LESSON_SECTION_PLUGINS } from './firia-markdown-plugins/LessonSection/LessonSectionPlugins'
import FiriaToolboxPlugin from './firia-markdown-plugins/ToolboxPlugin'
// import SyntaxHighlighter from 'react-syntax-highlighter'   // Hightlight.js version
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus as darkStyle, vs as lightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism'

// Patch lightStyle font-size to match darkStyle, which also corrects line-mismatch when showLineNumbers enabled.
lightStyle['code[class*="language-"]'].fontSize = '13px'

// Map theme 'palette' names to syntax-highlighting styles
const syntaxStyles = {
  dark: darkStyle,
  light: lightStyle,
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body1.fontFamily,
    '& pre': {
      width: 'fit-content',
    },
    '& h1': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
      fontSize: '2em',
    },
    '& h2': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
    },
    '& i': {
      verticalAlign: 'middle',
    },
    '& blockquote': {
      marginBlockStart: '0.1em',
      marginBlockEnd: '0.1em',
    },
    '& table': {
      borderCollapse: 'collapse',
    },
    '& thead': {
      background: theme.palette.action.selected,
    },
    '& th': {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
    },
    '& td': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
    },
    '& tr:nth-child(2n)': {
      // background: theme.palette.action.selected,
    },
    '& a': {
      color: theme.palette.info.light,
    },
    '& summary': {
      outline: 'none',
    },
  },
  newTool: {
    cursor: 'help',
    color: theme.palette.info.main,
    whiteSpace: 'nowrap',
  },
  ownedTool: {
    cursor: 'help',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  codeBlockScrollBar: {
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 3px 3px rgba(20, 20, 20,.3)',
      backgroundColor: 'rgba(88, 88, 88)',
    },
  },
}))

// Button component for reference to external CodeBot docs.
function DocsButton(props) {
  const style = Object.assign({float:'right', margin:5}, props.style)
  return (
    <div style={style}>
      <Button variant='text' onClick={() => window.open(props.href, '_blank')}>
        {props.name || 'Docs'}
        <LaunchIcon style={{width:20, marginLeft:5}} />
      </Button>
    </div>
  )
}

// Button component for reference to external Python/Micropython docs.
// TODO: Provide pyref AND additional mpref which if present gives a separate mini-button to open
//       corresponding micropython docs page.
function PyDocsButton(props) {
  // console.log(props.test)
  const style = Object.assign({float:'right', margin:5}, props.style)

  return (
    <div style={style}>
      <Button variant='text' onClick={() => window.open(props.pyref, '_blank')}>
        {props.name + ' - Python' || 'Python Docs'}
        <LaunchIcon style={{width: 20, marginLeft: 5}} />
      </Button>
      {props.mpref && (
        <Button variant='text' onClick={() => window.open(props.mpref, '_blank')}>
          {props.name + ' - MicroPython' || 'MicroPython Docs'}
          <LaunchIcon style={{width: 20, marginLeft: 5}} />
        </Button>
      )}
    </div>
  )
}

const InlineTool = (props) => {
  const classes = useStyles()
  const [, missionDispatch] = useMission()

  return (
    <span onClick={async (ev) => {
      ev.stopPropagation()  // prevent tool-click passing-through to quiz answer for example
      await missionDispatch({type: MissionActions.TOOL_SELECT, tool: props.tool})
    }} className={isOwnedTool(props.tool) ? classes.ownedTool : classes.newTool}>
      <BuildIcon fontSize='inherit' style={{verticalAlign: 'center'}} />{props.children}
    </span>
  )
}

function CodeTrek(props) {
  const [, missionDispatch] = useMission()
  return (
    <Button
      style={props.style ? props.style : {}}
      onClick={() => {
        const start = props.marker ? props.marker : null
        let steps = codeMarkerController.allAvailableSteps.map(step => step.marker)
        if (props.available) {
          steps = props.available
        } else if (props.marker && !props.full) {
          steps = [props.marker]
        }
        missionDispatch({type: MissionActions.TREX_SHOW, isOpen: true, startMarker: start, steps: steps})
      }}
    >
      CodeTrek <DirectionsWalkIcon /> <LaunchIcon style={{width: 20, marginLeft: 5}} />
    </Button>
  )
}

// TODO: This doesnt work for multiple footnotes right now
function FootnoteDefinition(props) {
  return (
    <div className='footnotes'>
      <hr/>
      <ol>
        <li id={'fn' + props.label} >
          {props.children}
        </li>
      </ol>
    </div>
  )
}

const components = {
  CodeTrek,
  PyDocsButton,
  DocsButton,
}

function addCopyButton(enableCopy, copyText, component) {
  if (enableCopy) {
    return (
      <div style={{display:'flex'}}>
        {component}
        <Tooltip title='Copy code to clipboard' placement='left' arrow>
          <IconButton style={{alignSelf:'flex-start', marginLeft:2}}
            onClick={() => navigator.clipboard.writeText(copyText)}>
            <CopyIcon style={{width:20}} />
          </IconButton>
        </Tooltip>
      </div>
    )
  } else {
    return component
  }
}
function CodeBlock(props) {
  const style = syntaxStyles[props.syntaxStyle || 'dark']
  const language = props.language || 'python'
  const classes = useStyles()

  if (language === 'react') {
    return <JsxParser jsx={props.value} components={components} />
  }

  // Allow options to be passed after language, separated by commas
  const langOpts = language.split(',')
  const lang = langOpts[0]

  const enableCopyButton = langOpts.includes('copy')
  const enableLineNumbers = props.showLineNumbers || langOpts.includes('numbered')

  return (
    props.value ?
      addCopyButton(enableCopyButton, props.value, (
        <SyntaxHighlighter
          language={lang}
          style={style}
          showLineNumbers={enableLineNumbers}
          className={props.syntaxStyle === 'dark' ? classes.codeBlockScrollBar : null}
          PreTag={props.inline ? 'span' : 'pre'}
          customStyle={props.inline ?
            {padding:0, borderColor: '#00000000', borderStyle:'solid', borderWidth:'thin', borderRadius:'0.3em'} :
            {padding:'3px', borderStyle:'solid', borderColor: 'rgb(127, 127, 127)', borderWidth:'1px', maxWidth:'90%',
              minWidth: '50%', marginLeft:'6%', marginTop:0, userSelect:'none', width:enableLineNumbers?'90%':'fitContent'}
          }
          children={props.value}
        />)) :
      (<span>ERROR: Invalid Code Block!!</span>)
  )
}
CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string,
  showLineNumbers: PropTypes.bool,
  inline: PropTypes.bool,
  syntaxStyle: PropTypes.string,
}
CodeBlock.defaultProps = {
  showLineNumbers: false,
  inline: false,
}

const scrubDisambiguation = (toolKey) => {
  // The tool.matches[] strings can include a disambiguation prefix, e.g. matches: ['codeair::pixel led',...]
  // Strip this prefix if present, so it's invisible to the user.
  return toolKey.includes('::') ? toolKey.split('::')[1] : toolKey
}

const mdProps = (props, currentTheme, classes) => {
  const newProps = {
    ...props,
    escapeHtml: false,
    linkTarget: '_blank',
    className: props.className ? `${props.className} ${classes.root}` : classes.root,

    // react-markdown uses 'remark' plugins to parse markdown syntax extensions
    // https://github.com/remarkjs/remark/blob/HEAD/doc/plugins.md#list-of-plugins
    plugins: [
      RemarkMathPlugin,  // Parse $$block$$ and $inline$ math syntax
      RemarkFootnotesPlugin, // Parse [^1] into a footnote
      FiriaToolboxPlugin, // Parse ==toolbox==
      ...LESSON_SECTION_PLUGINS,
    ],

    // react-markdown 'renderers' are React components used for each parsed 'node'
    renderers: {
      ...props.renderers,
      math: ({ value }) => <BlockMath>{value}</BlockMath>,
      inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
      code: props => (CodeBlock( {...props, syntaxStyle:currentTheme } )),
      inlineCode: props => (CodeBlock( {...props, syntaxStyle:currentTheme, inline: true } )),
      toolbox: ({ value }) => <InlineTool tool={value}>{scrubDisambiguation(value)}</InlineTool>,
      footnoteReference: props => <sup><a href={'#fn' + props.label} id={'fnref' + props.label}>[{props.label}]</a></sup>,
      footnoteDefinition: props => <FootnoteDefinition {...props} ></FootnoteDefinition>,
      ...LESSON_SECTION_PANELS,
    },
  }

  return newProps
}

// Filtering markdown, currently just to cache-bust images.
// Expedient but not an awesome solution... we will probably will remove this and transition to using
// custom render nodes (as is done for toolbox) for images we can serve from database.
const imgRegex = /img\s+src\s*=\s*["'][^"']+/gm

function firiaFilterMarkdown(contentText) {
  return contentText.replace(imgRegex, '$&' + cacheBust)
}

function FiriaMarkdown(props) {
  const theme = useTheme()
  const classes = useStyles()
  const [contentText, setContentText] = React.useState('')

  // console.log(`MD: props.children=${props.children && props.children.slice(0,10)}..., contentText=${contentText && contentText.slice(0,10)}..., mdFile=${props.mdFile}`)

  // If a markdown file path is provided in props.mdFile, go fetch the content.
  // Otherwise, the content defaults to the provided props.children which should be a markdown string.

  useEffect(() => {
    let text = (typeof(props.children) === 'string' && props.children[0] !== '/') ? props.children : ''
    if (text) {
      setContentText(text)
    } else {
      let mdFile = props.mdFile || (props.children[0] === '/' && props.children)
      if (!mdFile) {
        if (Array.isArray(props.children)) {
          for (const child of props.children) {
            if (typeof(child) === 'string') {
              text = text + child
            } else {
              console.warn('Markdown Error: Expected \'string\', got \'' + typeof(child) + '\'')
              console.log(child)
              console.log('NOTE: child is ' + (('$$typeof' in child) ? '' : 'not ') + 'a JSX component')
            }
          }
          setContentText(text)
        }
        return
      }

      const controller = new AbortController()
      const signal = controller.signal

      // On slow connections, replace stale contentText with loading message while we wait.
      setContentText('*Loading...*')

      async function fetchData() {
        try {
          const response = await fetchHandler(mdFile, {signal})
          const text = await response.text()
          setContentText(text)
        } catch (e) {
          if (e.name !== 'AbortError') {
            console.log(`Error fetching markdown file: ${mdFile}`, e)
          }
        }
      }
      fetchData()

      return function cleanup() {
        // Cancel fetch() when component unmounts or is re-rendered
        controller.abort()
      }
    }
  }, [props.children, props.mdFile])

  const filteredContentText = firiaFilterMarkdown(contentText)

  return (<ReactMarkdown {...mdProps(props, theme.palette.type, classes)}>{filteredContentText}</ReactMarkdown>)
}
FiriaMarkdown.propTypes = {
  mdFile: PropTypes.string,
}

export default FiriaMarkdown
