import React from 'react'
import { Button, Dialog, Typography, LinearProgress, DialogContent, Select, MenuItem, Divider, Snackbar, makeStyles } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { FiriaGreenButton } from '../FiriaGreenButton'
import { DEVICES } from './device-flash-constants'
import { DeviceFlasherProvider, useDeviceFlasher } from './DialogFlasherContext'
import { genContrastColor } from '../utils/shade-highlight-tools'
import Markdown from '../FiriaMarkdown'

const useStyles = makeStyles(() => ({
  scrollbar: {
    '&::-webkit-scrollbar': {
      border: 'solid 3px transparent',
      width: 15,
    },
    //   '&::-webkit-scrollbar': {
    //     width: 10px;
    // },

    '&::-webkit-scrollbar-track': {
      border: 'solid 4px rgba(0,0,0,0)',
    },

    '&::-webkit-scrollbar-thumb': {
      // boxShadow: inset 0 0 10px 10px red;
      border: 'solid 4px rgba(0,0,0,0)',
    },
  },
}))

function LabelArrow({ orientation }) {
  let outer = {
    width: 0,
    height: 0,
  }
  let inner = {
    width: 0,
    height: 0,
  }

  if (orientation === 'right') {
    outer = {...outer,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderLeft:'8px solid black',
    }
    inner = {...inner,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderLeft:'8px solid #F4D038',
      position:'absolute',
      paddingRight: 2,
    }
  }

  if (orientation === 'left') {
    outer = {...outer,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderRight:'8px solid black',
    }
    inner = {...inner,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderRight:'8px solid #F4D038',
      position:'absolute',
      paddingLeft: 2,
    }
  }

  if (orientation === 'top') {
    outer = {...outer,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderBottom:'8px solid black',
    }
    inner = {...inner,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderBottom:'8px solid #F4D038',
      position:'absolute',
      paddingTop: 2,
    }
  }

  return (
    <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
      <div
        style={outer}>
      </div>
      <div
        style={inner}>
      </div>
    </div>
  )
}

function Label({ text, x, y, orientation='right' }) {
  return (
    <div style={{position: 'absolute', paddingLeft: x, paddingTop: y, display: 'flex', flexDirection: (orientation === 'left' || orientation === 'right') ? 'row':'column'}}>
      {(orientation === 'left' || orientation === 'top') && <LabelArrow orientation={orientation}/>}
      <div style={{backgroundColor: '#F4D038', color:'black', padding: '3px 10px', border: '1px solid BLACK', borderRadius: 5}}>
        <Typography variant='h6'>
          {text}
        </Typography>
      </div>
      {(orientation === 'right' || orientation === 'bottom') && <LabelArrow orientation={orientation}/>}
    </div>
  )
}

function LabeledImage() {
  const { device } = useDeviceFlasher()
  const { imgSrc, imgStyle, labels } = device.labeledImage
  const [loaded, setLoaded] = React.useState(false)

  return (
    <div style={{ width: 350, height: 350, position: 'relative'}}>
      {loaded ? labels.map(label => <Label {...label}/>) : null}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        height: 350,
        overflow: 'hidden',
        alignItems:'center',
        justifyContent:' center',
        borderRadius: 5,
        border: `1px solid ${genContrastColor(0.4)}`,
        backgroundColor: genContrastColor(0.03, 0.01),
      }}>
        <div style={{display: 'flex', alignItems:'center', justifyContent:' center'}}>
          <img src={`pub/${imgSrc}`} alt='' style={imgStyle} onLoad={() => setLoaded(true)}/>
        </div>
      </div>
    </div>
  )
}

function DeviceCard({ deviceId }) {
  const { setSelectedDeviceId } = useDeviceFlasher()
  const { title, img, imgStyle } = DEVICES[deviceId]
  const cb2 = deviceId === 'cb2'
  return (
    <Button
      variant='outlined'
      style={{textTransform: 'none', padding: 0, overflow:'hidden'}}
      href={cb2 ? 'https://upgrade.firialabs.com':null}
      target='_blank'
      onClick={cb2 ? () => {}:() => setSelectedDeviceId(deviceId)}
    >
      <div style={{display: 'flex', flexDirection: 'column', width: 205, overflowX: 'hidden', borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
        <div style={{display: 'flex', alignItems:'center', justifyContent:' center', backgroundColor: genContrastColor(0.06), borderBottom: `1px solid ${genContrastColor(0.1)}`, height: 150, overflow: 'hidden'}}>
          <img src={img} style={{height: 130, ...imgStyle}} alt=''/>
        </div>

        {cb2 ?
          <div style={{display: 'flex'}}>
            <div style={{flex: 1, display: 'flex', alignItems:'center', justifyContent:'center'}}>
              <Typography variant='subtitle1' style={{paddingTop: 10, paddingBottom: 10}}>{title}</Typography>
            </div>
            <div style={{borderLeft: '1px solid rgba(255, 255, 255, 0.23)', height: 48, display: 'flex', alignItems:'center', justifyContent:'center', width: 40}}>
              <LaunchIcon style={{fontSize: 18}}/>
            </div>
          </div>:
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5}}>
            <Typography variant='subtitle1' style={{paddingTop: 10, paddingBottom: 10}}>{title}</Typography>
          </div>
        }
      </div>
    </Button>
  )
}

function DeviceSelectorPanel() {
  return (
    <div style={{paddingTop: 20, height: '100%', minHeight: 260}}>
      <div style={{padding: 20, height: '100%', border: `1px solid ${genContrastColor(0.08, 0.1)}`, backgroundColor: genContrastColor(0.01), borderRadius: 3}}>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-around'}}>
          <DeviceCard deviceId={'codeair'} />
          <DeviceCard deviceId={'codex'} />
          <DeviceCard deviceId={'cb3'} />
          <DeviceCard deviceId={'cb2'} />
        </div>
      </div>
    </div>
  )
}

function FlasherPanel() {
  const { startUpgrade, progress, upgradeInProgress, device } = useDeviceFlasher()
  const [selectedFirmwareVersionFileName, setSelectedFirmwareVersionFileName] = React.useState(null)
  const firmwareVersions = device.firmware
  const selectedFileName = selectedFirmwareVersionFileName ?? firmwareVersions[0].fileName

  return (
    <div style={{paddingTop: 20, paddingBottom: 10}}>
      <div style={{display: 'flex', alignItems:'flex-start', justifyContent:'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography style={{fontWeight: 'bold', margin:'auto'}} variant='subtitle1'>
            {'Step 1: Enter Bootloader Mode'}
          </Typography>
          <Divider style={{ marginBottom: 10}}/>
          {device.steps.map((step, idx) => (
            <>
              {idx !== 0 && <div style={{height: 8}} />}
              <Typography>
                {`${idx + 1}. `}
                {step}
                {'.'}
              </Typography>
            </>
          ))}
          <Typography style={{fontWeight: 'bold', paddingTop: 30, margin:'auto'}} variant='subtitle1'>
            {'Step 2: Connect & Flash'}
          </Typography>
          <Divider style={{ marginBottom: 10}}/>
          <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 15}}>
            <div style={{width: 205}}>
              <Typography>
                {'1. Select a firmware version: '}
              </Typography>
            </div>
            <Select
              disabled={upgradeInProgress}
              variant='outlined'
              style={{height: 40, width: 300}}
              value={selectedFileName}
              onChange={ev => setSelectedFirmwareVersionFileName(ev.target.value)}
            >
              {
                firmwareVersions.map(({sha, date, fileName}, index) =>
                  <MenuItem
                    key={fileName}
                    value={fileName}
                  >
                    {date + ' ' + sha + (index === 0 ? ' (Latest)':'')}
                  </MenuItem>
                )
              }
            </Select>
          </div>

          <Typography style={{marginBottom: 10}}>
            {'2. Click the button below and select '}
            <b>{`"${device?.name}"`}</b>
            {' from the dropdown.'}
          </Typography>

          <div style={{width: 200, margin: 'auto'}}>
            <FiriaGreenButton
              onClick={() => startUpgrade(selectedFileName)}
              style={{width: '100%'}}
              variant='contained'
              disabled={upgradeInProgress}
            >
              {'Connect & Flash'}
            </FiriaGreenButton>
          </div>
        </div>
        <LabeledImage />
      </div>
      {progress !== null && <ProgressPanel />}
    </div>
  )
}

function Footer() {
  const { upgradeInProgress, selectedDeviceId, goBack, closeDialog } = useDeviceFlasher()

  return (
    <div style={{display: 'flex', justifyContent:'space-between', paddingBottom: 4, paddingTop: 10}}>
      {selectedDeviceId ?
        <Button variant='outlined' disabled={upgradeInProgress} onClick={goBack}>
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center', gap: 5}}>
            <KeyboardBackspaceIcon />
            {'Back'}
          </div>
        </Button>:
        <div/>}
      <Button variant='outlined' disabled={upgradeInProgress} onClick={closeDialog}>
        {'Close'}
      </Button>
    </div>
  )
}

function ProgressSuccessPanel() {
  const { device } = useDeviceFlasher()
  return (
    <center>
      <Typography>
        {'Upgrade complete, please press the '}
        <b>{`"${device.resetButtonName}"`}</b>
        {' button to exit Bootloader mode.'}
      </Typography>
    </center>
  )
}

function ProgressErrorPanel() {
  return (
    <center>
      <Typography>
        {'If the issue persists, please contact support.'}
      </Typography>
    </center>
  )
}

function ProgressPanel() {
  const { progress, upgradeComplete, upgradeError } = useDeviceFlasher()

  const id = 'flasher-prog-panel'
  React.useEffect(() => {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'})
  }, [])

  return (
    <div id={id} style={{height: 130}}>
      <div style={{margin: 'auto', width: '100%', paddingTop: 40}}>
        <center>
          <Typography variant='subtitle1' style={{fontWeight: 'bold', paddingBottom: 10}}>
            {upgradeError ? upgradeError + ', please try again.' : `Upgrade Progress: ${progress ?? 0}%`}
          </Typography>
        </center>
        <div style={{border: '1px solid rgba(0,0,0,0.5)'}}>
          {upgradeComplete ? <div style={{height:4, backgroundColor: 'green'}}/>:
            upgradeError ? <div style={{height:4, backgroundColor: 'red'}}/>:
              <LinearProgress variant='determinate' value={progress}/>}
        </div>
      </div>
      <div style={{paddingTop: 20}}>
        {upgradeComplete ? <ProgressSuccessPanel /> : upgradeError ? <ProgressErrorPanel /> : null}
      </div>
    </div>
  )
}

function Body() {
  const { selectedDeviceId } = useDeviceFlasher()
  if (!selectedDeviceId) {
    return <DeviceSelectorPanel />
  }

  return <FlasherPanel />
}

function DeviceFlasherPanel(){
  const classes = useStyles()

  const { selectedDeviceId, setSelectedDeviceId, dialogOpen, connectionError, device } = useDeviceFlasher()

  return (
    <Dialog
      open={dialogOpen}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick' ){
          return
        }
      }}
      maxWidth={'lg'}
      TransitionProps={{onExited: () => setSelectedDeviceId(null)}}
    >
      <DialogContent style={{width: 1000, height: 700, display: 'flex', flexDirection: 'column'}}>
        <div>
          <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
            <Typography
              variant='h6'
            >
              {'Device Firmware'}
            </Typography>
            {selectedDeviceId &&
            <Typography
              variant='h6'
              style={{margin:'auto', fontWeight: 'bold'}}
            >
              {device.title + ' Upgrade'}
            </Typography>
            }
            <div style={{width: 151}}/>
          </div>
          {!selectedDeviceId &&
          <Markdown>
            {
              `Select a device below to upgrade.

### Note:
This is normally only necessary when Firia Labs publishes a new
version of the firmware for your device.
* *CodeSpace checks the firmware version each time your device is connected, and will prompt you
   if it needs an update!*
`}
          </Markdown>
          }
        </div>
        <div
          className={classes.scrollbar}
          style={{
            flex: 1,
            overflowY: 'auto',
          }}>
          <Body />
        </div>
        <div>
          <Footer />
        </div>
      </DialogContent>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={15000}
        open={connectionError}
        message={`Whoops! The selected device wasn't a ${device?.title} in bootloader mode, please try again.`}
      >
        <div style={{backgroundColor: '#d32f2f', color: 'white', display: 'flex', alignItems:'center', gap: 10, padding: '12px 16px', borderRadius: 5}}>
          <ErrorOutlineIcon />
          {`Whoops! The selected device wasn't a ${device?.title} in bootloader mode, please try again.`}
        </div>
      </Snackbar>
    </Dialog>
  )
}

function DeviceFlasherDialog() {
  return (
    <DeviceFlasherProvider>
      <DeviceFlasherPanel />
    </DeviceFlasherProvider>
  )
}

export { DeviceFlasherDialog }