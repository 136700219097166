import { getActiveMissionPack } from './getActiveMissionPack'
import { getActiveMission } from './getActiveMission'
import { userSessionStore } from '../../user-session/user-session-store'

// Need to manually lock a pack? Simply add a new entry to the lockedPacks object below.
// The key needs to be the pack id, fill the value with an object with a lockedMissionsList key that contains a list of locked mission ids.
const lockedPacks = {
  PACK_ID: {
    lockedMissionsList: ['MISSION_ID'],
  },
  LCX: {
    lockedMissionsList: [
      // 'LCX_LineArt',
      // 'LCX_Breakout',
      'LCX_LuxeGraphics',
      'LCX_MelodyTime',
      'LCX_WetPaint',
      'LCX_SynthWave',
      'LCX_MicDrop',
      'LCX_BallMaze',
      'LCX_Instrumental',
      'LCX_AlarmSensors',
      'LCX_RadioMessenger',
      'LCX_CyberX',
    ],
  },
  FLY: {
    lockedMissionsList: [
      'FLY_Attitude',
      'FLY_Survey',
      'FLY_Recon',
    ],
  },
}

// Want to test out a new mission pack live? Add its packId to this list and log in with a
// whitelisted user email.
const DEVELOPMENT_PACK_IDS = ['PWR']
function getAvailableDevelopmentPackIds(){
  const userSession = userSessionStore.readUserSession()
  if (!userSession || !whitelistedUserEmails.find(email => email === userSession.email)) {
    return []
  }

  return DEVELOPMENT_PACK_IDS
}

function getLockedPackInfo() {
  const { id: missionPackId } = getActiveMissionPack()
  return lockedPacks[missionPackId]
}

function unsafeIsMissionManuallyLocked(missionId) {
  if (!getLockedPackInfo()) {
    return false
  }

  return !!getLockedPackInfo().lockedMissionsList.find(lockedId => lockedId === missionId)
}

function unsafeIsNextMissionManuallyLocked() {
  if (!getLockedPackInfo()) {
    return false
  }

  const { missions } = getActiveMissionPack()
  const { id: activeMissionId } = getActiveMission()
  const activeMissionIndex = missions.findIndex(mission => mission.id === activeMissionId)
  if (activeMissionIndex === -1) {
    return false
  }

  if (activeMissionIndex === missions.length - 1) {
    return false
  }

  const nextMissionId = missions[activeMissionIndex + 1].id
  return isMissionManuallyLocked(nextMissionId)
}

const whitelistedUserEmails = [
  'jeff@firia.com', 'david@firia.com', 'sarah@firia.com', 'sarabethewing@gmail.com',
  'jill@firia.com', 'jacob@firia.com',
]

const isMissionManuallyLocked = (missionId) => {
  const userSession = userSessionStore.readUserSession()
  if (userSession && !!whitelistedUserEmails.find(email => email === userSession.email)) {
    return false
  }

  try {
    return unsafeIsMissionManuallyLocked(missionId)
  } catch {
    //
  }
}

const isNextMissionManuallyLocked = () => {
  try {
    return unsafeIsNextMissionManuallyLocked()
  } catch {
    //
  }
}

export { isMissionManuallyLocked, isNextMissionManuallyLocked, getAvailableDevelopmentPackIds }