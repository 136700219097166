// Mission Select Dialog
import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Button, Typography, Paper, Tooltip, CircularProgress, Fade } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { genContrastColor } from '../utils/shade-highlight-tools'

const alertColor = '#ff3333'
const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: alertColor,
  },
  arrow: {
    color: alertColor,
  },
}))

export const FiriaGreenButtonBase = withStyles(theme => ({
  root: {
    borderRadius: 2,
    color: theme.palette.getContrastText('#4CAF50'),
    backgroundColor: '#4CAF50',
    '&:hover': {
      backgroundColor: '#3d8a40',
    },
  },
}))(Button)

export const FiriaGreenButton = (props) => {
  return (
    <Paper elevation={5}>
      <FiriaGreenButtonBase {...props}>
        {props.children}
      </FiriaGreenButtonBase>
    </Paper>
  )
}

const ErrorTooltip = (props) => {
  const classes = useStyles()
  const tooltipTimeout = React.useRef(null)
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const open = props.open

  React.useEffect(() => {
    if (open){
      setTooltipOpen(true)
      clearTimeout(tooltipTimeout.current)
      tooltipTimeout.current = setTimeout(()=>{
        setTooltipOpen(false)
      }, 10000)
    }

    return () => {
      clearTimeout(tooltipTimeout.current)
      setTooltipOpen(false)
    }
  }, [open])

  const parseProps = () => {
    const {
      open,
      ...formattedProps
    } = props
    return formattedProps
  }

  const onMouseEnter = () => {
    setTooltipOpen(true)
    clearTimeout(tooltipTimeout.current)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
    clearTimeout(tooltipTimeout.current)
  }


  return (
    <Tooltip
      leaveDelay={500}
      arrow
      placement={'top'}
      open={tooltipOpen && props.open}
      classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
      {...parseProps()}
    >
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {props.children}
      </div>
    </Tooltip>
  )
}

export const FetchResp = (props) => {
  const open = props.open
  const setErrorMsg = props.setErrorMsg
  const setFetchStatus = props.setFetchStatus
  const fetchStatus = props.fetchStatus
  const checkVisibleDuration = props.checkVisibleDuration
  const [checkVisible, setCheckVisible] = React.useState(false)
  const isMounted = React.useRef(false)
  const checkTimeout = React.useRef(null)

  React.useEffect(() => {
    if (!open){
      if (setFetchStatus){
        setFetchStatus(null)
      }
      if (setErrorMsg){
        setErrorMsg(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  React.useEffect(()=>{
    isMounted.current = true
    return ()=>{
      clearTimeout(checkTimeout.current)
      isMounted.current = false
    }
  }, [])

  React.useEffect(()=>{
    if (fetchStatus === 'success' && checkVisibleDuration){
      setCheckVisible(true)
      clearTimeout(checkTimeout.current)
      checkTimeout.current = setTimeout(()=>{
        if (isMounted.current){
          setCheckVisible(false)
        }
      }, checkVisibleDuration)
    }
  }, [fetchStatus, checkVisibleDuration])

  const formatErrorMsg = (msg) => {
    switch (msg) {
      case 'failed getting group data from joincode':
        return 'The submitted join code is not assigned to a group!'
      case 'assigned license full':
        return 'The group you\'re trying to join is full!'
      case 'group member already exists':
        return 'You\'re already a member of the group!'
      case 'group unjoinable':
        return 'The group has join code functionality disabled!'
      case 'no available assigned licenses':
        return 'Your license is not active, please check with your administrator!'
      default:
        return 'An unexpected error has occured!'
    }
  }

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent: 'flex-end'}}>
      {fetchStatus === 'waiting' ?
        <CircularProgress style={{color:genContrastColor(1)}} size={props.size ? props.size - 5 : 20}/>:
        null}
      {fetchStatus === 'success' ?
        <Fade
          in={checkVisibleDuration ? checkVisible : true}
          timeout={{enter: 0, exit: 800}}
        >
          <CheckIcon style={{color: 'green', fontSize: props.size }}/>
        </Fade>:
        null}
      {fetchStatus === 'failed' ?
        <ErrorTooltip
          placement={props.placement ?? 'top'}
          open={Boolean(fetchStatus === 'failed' && props.open && props.errorMsg)}
          title={
            <>
              <Typography
                style={{width: 200}}
                variant='body2'
              >
                {formatErrorMsg(props.errorMsg)}
              </Typography>
            </>
          }
        >
          <ErrorOutlineIcon style={{color: alertColor, display:'flex', alignItems: 'center', justifyContent: 'center', fontSize: props.size}}/>
        </ErrorTooltip>:null
      }
    </div>
  )
}
