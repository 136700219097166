import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { WindowsButton } from './FileSystemModals'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useScene, SceneActions } from './contexts/SceneContext'
import { Targets, TargetNames } from './Players'
import UserSettings from './UserSettings'
import Markdown from './FiriaMarkdown'
import AboutPanel from './assets/AboutPanel.md'
import WelcomePanel from './assets/WelcomePanel.md'
import { useMission, targetRequiresSim } from './contexts/MissionContext'
import { MessageDialog } from './Dialogs'
import { SandboxIcon } from './assets/SvgIcons'
import { Usb } from '@material-ui/icons'
import { CircularProgress, Tooltip } from '@material-ui/core'
import { genContrastColor } from './utils/shade-highlight-tools'

const useStyles = makeStyles((theme) => {
  return ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outlineStyle: 'none',
      padding: 10,
      paddingTop: 15,
      paddingBottom: 15,
    },
    aboutContent: {
      margin: '1em',
      height: 162,
      width: 642,
    },
    rowFlex: {
      display: 'flex',
      flexDirection: 'row',
    },
    colFlex: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    headerContainer: {
      width: '100%',
      paddingBottom: 5,
    },
    headerDivider: {
      width: '100%',
      paddingLeft: 15,
      paddingTop: 12,
    },
    settingsContainer: {
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 17,
    },
    circularProgress: {
      color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.8',
      marginLeft: 5,
      marginTop: 5,
    },
  })
})

const SectionHeader = (props) => {
  const classes = useStyles()
  return (
    <div className={`${classes.rowFlex} ${classes.headerContainer}`}>
      <div className={props.titleClass}>
        {props.title}
      </div>
      <div className={classes.headerDivider}>
        <Divider />
      </div>
    </div>
  )
}

const SettingsSection = (props) => {
  const classes = useStyles()

  return (
    <div style={{ paddingBottom: 25 }}>
      <SectionHeader
        title={props.title}
        titleClass={classes.title}
      />
      {props.children}
    </div>
  )
}

export function SettingsModal({ open, onClose }) {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      style={{ height: '85%' }}
    >
      <Paper className={classes.paper} style={{ width: 700, padding: 15, paddingTop: '10px' }}>
        <div className={`${classes.colFlex} ${classes.settingsContainer}`} style={{ height: '100%' }}>
          <div className={`${classes.colFlex} ${classes.settingsContainer}`}>
            <SettingsSection title={'Preferences'}>
              <div>
                <UserSettings />
              </div>
            </SettingsSection>
          </div>
          <div className={`${classes.rowFlex} ${classes.buttonContainer}`}>
            <WindowsButton
              onClick={onClose}
              label={'Close'}
            />
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export function TargetSelectionModal({ open, onClose }) {
  const classes = useStyles()
  const [sceneState, sceneDispatch] = useScene()
  const [missionState] = useMission()
  const [target, setTarget] = React.useState(sceneState.target)
  const [cnxReq, setCnxReq] = React.useState(false)
  const [pairReq, setPairReq] = React.useState(false)
  const [informExploreMode, setInformExploreMode] = React.useState(false)
  const isExploreMode = !!missionState.ui.isExploreMode
  const isTargetConnected = (
    (target === sceneState?.device) ||
    (target === Targets.USB_CODEBOT && (sceneState?.device === Targets.USB_CB2 || sceneState?.device === Targets.USB_CB3))
  )

  // When not Explore mode, Mission/Objective target should be same as sceneState.target.
  // However, we have in the past seen users reporting "NO DEVICE CONNECTED" when Objective should've set target.
  // TODO (?): Check if there's a mismatch and allow target-select to fix. This would allow
  //           user to compensate for above bug... IF it still exists. Waiting on this.
  // const missionObjectiveTarget = isExploreMode ? null :
  //   (missionState.activeMission.flow[missionState.ui.curFlowIndex].obj.target !== undefined ?
  //     missionState.activeMission.flow[missionState.ui.curFlowIndex].obj.target : Targets.SIM_CB2)

  // Update target state when modal opens or sceneState.target changes
  React.useEffect(() => {
    if (open) {
      setTarget(sceneState.target)
    }
  }, [open, sceneState.target])

  // If user pressed USB connect button (cnxReq=true) and we got connected, then close immediately
  React.useEffect(() => {
    if (cnxReq && isTargetConnected) {
      setCnxReq(false)
      onClose()
    }
  }, [cnxReq, isTargetConnected, onClose])

  // Trigger device pairing when user presses USB connect button
  React.useEffect(() => {
    if (pairReq && target === sceneState.target && !isTargetConnected) {
      setPairReq(false)
      sceneDispatch({ type: SceneActions.TARGET_DEVICE_PAIR, target: target })
    }
  }, [cnxReq, isTargetConnected, target, sceneState.target, pairReq, sceneDispatch])

  const targetUnavailable = targetRequiresSim(target) && sceneState.sceneIsResetting
  async function changeTarget() {
    if (targetUnavailable) {
      return
    }
    if (targetRequiresSim(target)) {
      sceneDispatch({
        type: SceneActions.TARGET_AND_ENVIRONMENT_SET,
        requiresSim: true,
        target: target,
        camIndex: 0,
        envIndex: 1,
        params: {},
      })
    } else {
      await sceneDispatch({ type: SceneActions.TARGET_DEVICE_SET, target: target })
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      style={{ height: '70%' }}
    >
      <div>
        <Paper className={classes.paper} style={{ width: 300, height: 140, padding: 10 }}>
          <div className={classes.colFlex} style={{ justifyContent: 'center', gap: 3 }}>
            <div>Select Target</div>
            <div><Divider /></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', paddingTop: 10 }}>
              {/* Target list dropdown  */}
              <Select
                value={target}
                style={{ width: '75%' }}
                disabled={!isExploreMode}
                onClick={!isExploreMode ? () => setInformExploreMode(true) : null}
              >
                {Object.keys(Targets).map(function (targetKey, index) {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setCnxReq(false)
                        setTarget(Targets[targetKey])
                      }}
                      value={Targets[targetKey]}>
                      {TargetNames[targetKey]}
                    </MenuItem>
                  )
                })}
              </Select>
              {targetUnavailable &&
              <div style={{width: '20%'}}>
                <Tooltip title={'Please wait for the simulator to complete loading before selecting another simulated target.'} placement='right' arrow>
                  <CircularProgress size={20} className={classes.circularProgress} />
                </Tooltip>

              </div>}
              {/* Button with USB icon  */}
              <Button
                onClick={async () => {
                  setCnxReq(true)
                  await changeTarget()
                  setPairReq(true)
                }}
                variant='outlined'
                size='small'
                style={{
                  marginLeft: '1em',
                  visibility: TargetNames[target].includes('USB') ? 'visible' : 'hidden',
                  color: isTargetConnected ? 'green' : genContrastColor(1),
                  height: 32,
                }}
                disabled={isTargetConnected}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Usb style={{fontSize: 18}} />
                  <p style={{ width: 62, margin: '0px 4px', fontSize: isTargetConnected ? 'x-small' : 'small' }}>{isTargetConnected ? 'Connected' : 'Connect'}</p>
                </div>
              </Button>
            </div>
          </div>
          <div className={`${classes.rowFlex} ${classes.buttonContainer}`} style={{ marginTop: '1.5em', gap: 10 }}>
            <WindowsButton
              onClick={async () => {
                await changeTarget()
                onClose()
              }}
              disabled={targetUnavailable}
              label={'OK'}
            />
            <WindowsButton
              onClick={onClose}
              label={'Cancel'}
            />
          </div>
        </Paper>

        <MessageDialog
          open={informExploreMode}
          title='Cannot Change Target Device'
          onClose={() => setInformExploreMode(false)}
        >
          <p>Target is set by your current Mission / Objective.</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>
              Please enter Sandbox mode if you want to change the target device. Click the <b>Sandbox</b> icon
              near the <em>bottom-right of your window</em> to enter Sandbox mode. Just click the button again to return
              to your Mission!
            </p>
            <SandboxIcon style={{ fontSize: '2em', marginLeft: 4 }} />
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              variant='outlined'
              style={{ marginLeft: 'auto' }}
              onClick={() => setInformExploreMode(false)}
            >
              OK
            </Button>
          </div>
        </MessageDialog>
      </div>
    </Modal>
  )
}

const HeaderPanelModal = (props) => {
  const classes = useStyles()

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={classes.modal}
      style={{ height: '85%' }}
    >
      <Paper className={classes.paper} style={{ padding: '15px', paddingTop: '10px', width: props.width ?? null, height: props.height ?? null }}>
        <SectionHeader title={props.title} />
        <div style={{ flexGrow: 1 }}>
          <Markdown>{props.panel}</Markdown>
        </div>
        <div className={`${classes.rowFlex} ${classes.buttonContainer}`}>
          <WindowsButton
            onClick={props.onClose}
            label={'Close'}
          />
        </div>
      </Paper>
    </Modal>
  )
}

export function WelcomePanelModal(props) {
  return (
    <HeaderPanelModal
      title={'Welcome'}
      panel={WelcomePanel}
      {...props}
    />
  )
}

export function AboutPanelModal(props) {
  return (
    <HeaderPanelModal
      width={700}
      title={'About'}
      panel={AboutPanel}
      {...props}
    />
  )
}

