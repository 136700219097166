import authFetchHandler from '../../tools/auth/authFetchHandler'
import { codeFilesDatabase } from '../../databaseworker/databases'
import { textToBlob } from '../../databaseworker/fileUtils'
import { metadataDeserializer, metadataSerializer } from '../../databaseworker/jsonUtils'
import { lowToleranceAuthFetcher } from '../utils/low-tolerance-auth-fetcher'

const LOGGED_OUT_FILE_ID = 'loggedOutFile'
class CodeFileGateway {
  async saveToCloud(fileId, content, metadata) {
    const form = new FormData()
    form.append('file', content instanceof Blob ? content : textToBlob(content), fileId)
    if (!!metadata) {
      form.append('metadata', JSON.stringify(metadata, metadataSerializer))
    }

    return await lowToleranceAuthFetcher(`postFile/${encodeURIComponent(codeFilesDatabase.name)}`, {
      method: 'POST',
      body: form,
    })
  }

  async getFromCloud(fileId, initialization, lowTolerance = true) {
    let handleFetch = lowTolerance ? lowToleranceAuthFetcher : authFetchHandler
    const resp = await handleFetch(`getFile/${encodeURIComponent(codeFilesDatabase.name)}/${fileId}`, {
      method: 'GET',
    }, [], initialization)
    if (resp.status === 200) {
      return await resp.text()
    }
  }

  async deleteFromCloud(fileId, metadata) {
    return await lowToleranceAuthFetcher(`delete/${encodeURIComponent(codeFilesDatabase.name)}/${fileId}`, {
      method: 'POST',
      body: JSON.stringify({
        metadata,
      }),
    })
  }

  async saveMetadataToCloud(metadata) {
    return await lowToleranceAuthFetcher(`postMetadata/${encodeURIComponent(codeFilesDatabase.name)}`, {
      method: 'POST',
      body: JSON.stringify({
        metadata,
      }),
    })
  }

  async getMetaDataFromCloud(fileId) {
    try {
      const resp = await authFetchHandler(`files/code/${fileId}/meta-data`,
        {
          method: 'GET',
        })

      return await resp.json()
    } catch (err){
      //
    }
  }

  async getFileTreeFromCloud() {
    const resp = await lowToleranceAuthFetcher('getUserFileTree', {
      method: 'GET',
    }, [], true)
    if (resp.status !== 200) {
      return
    }
    // Deserialize the response into our tree structure
    return JSON.parse(await resp.text(), metadataDeserializer)
  }
}

const codeFileGateway = new CodeFileGateway()
export {
  LOGGED_OUT_FILE_ID,
  codeFileGateway,
}