import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip, Badge } from '@material-ui/core'
import { useMissionEditor, MissionEditorActions } from './contexts/MissionEditorContext'
import {
  Bookmark, AssignmentTurnedIn, MenuBook, DirectionsWalk, Code, Assignment, EmojiObjects,
  Dashboard, AssignmentReturned, OpenInBrowser, Room, Ballot, FormatListNumbered, VpnKey,
  LiveHelp, ContactSupport, Work, Streetview, InsertEmoticon, Sync, Usb, List, FolderSpecial,
  NightsStay, WbSunny,
} from '@material-ui/icons'
import { missionEditorController } from './MissionEditorControl'
import { FetchResp } from './MyClassesDialog/FetchResp'
import TeachersManualRipperDialog from './MissionEditorTools/TeachersManualRipper/TeachersManualRipperDialog'
import { useDebugger, DebuggerActions } from './contexts/DebuggerContext'
import { TargetSelectionModal } from './ToolBarModal'
import { RESPONSE_STATUS } from './content-manager/fetch-responses'
import CompressPacksDialog from './MissionEditorTools/CompressPacksDialog'
import ValidatorTestsDialog from './MissionEditorTools/ValidatorTestsDialog'
import { useUserConfig, UserConfigActions } from './contexts/UserConfigContext'

const useStyles = makeStyles(theme => ({
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems:'center',
    height: '100vh',
  },
  buttonGroup: {
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  small: {
    width: 28,
    height: 28,
    display: 'block',
    borderRadius: '5px',
  },
  outlined: {
    border: '1px solid white',
    borderRadius: '5px',
  },
  topBorder: {
    height: 3,
    width: '100%',
    borderTop: '1px solid white',
    marginTop: 5,
    marginBottom: 5,
  },
  hidden: {
    display: 'none',
  },
  fetchRespContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingRight: 2,
  },
  badgeInProgress: {
    backgroundColor: 'yellow',
  },
  badgeSuccess: {
    backgroundColor: 'green',
  },
  badgeFailed: {
    backgroundColor: 'red',
  },
  badgeDefault: {
    backgroundColor: 'grey',
  },
  badgeBorder: {
    border: '1px solid rgba(0,0,0,0.3)',
  },
}))

function BottomButtonGroup({ setTeachersManualDialogOpen, setCompressPacksDialogOpen, setValidatorTestsDialogOpen }) {
  const classes = useStyles()
  const [{ validatorTestState }] = useMissionEditor()
  const [userConfigState, userConfigDispatch] = useUserConfig()

  function getBadgeColorClass(){
    if (validatorTestState.status === RESPONSE_STATUS.IN_PROGRESS) {
      return classes.badgeInProgress
    }
    if (validatorTestState.status === RESPONSE_STATUS.SUCCEEDED) {
      return classes.badgeSuccess
    }
    if (validatorTestState.status === RESPONSE_STATUS.FAILED) {
      return classes.badgeFailed
    }
    return classes.badgeDefault
  }

  function onThemeToggle() {
    userConfigDispatch({ type: UserConfigActions.THEME_SET, theme: userConfigState.lightTheme ? 'dark':'light' })
  }

  const testLength = validatorTestState.tests.length
  const noFailedTests = testLength === 0
  return (
    <div className={classes.buttonGroup}>
      <Tooltip title={'Theme Toggle'} placement='right' arrow>
        <IconButton
          size='small'
          className={classes.small}
          style={{border: '1px solid rgba(255,255,255,0.2)'}}
          aria-label='feedback'
          disableRipple
          onClick={onThemeToggle}
        >
          {userConfigState.lightTheme ?
            <NightsStay style={{marginTop: -2}}/> :
            <WbSunny style={{marginTop: -2}} />
          }
        </IconButton>
      </Tooltip>
      <Tooltip title={'Validator Tests'} placement='right' arrow>
        <IconButton
          size='small'
          className={classes.small}
          style={{border: '1px solid rgba(255,255,255,0.2)'}}
          aria-label='feedback'
          disableRipple
          onClick={async () => {
            setValidatorTestsDialogOpen(true)
          }}
        >
          <Badge
            badgeContent={noFailedTests ? null:testLength}
            invisible={false}
            variant={'dot'}
            classes={{ badge: `${classes.badgeBorder} ${getBadgeColorClass()}` }}
            style={{marginTop: -2}}
          >
            <List />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title={'Create Teacher\'s Manual'} placement='right' arrow>
        <IconButton
          size='small'
          className={classes.small}
          aria-label='feedback'
          disableRipple
          component='label'
          style={{border: '1px solid rgba(255,255,255,0.2)'}}
          onClick={(e) => {
            setTeachersManualDialogOpen(true)
          }}
        >
          <Bookmark style={{marginTop: -2}}/>
        </IconButton>
      </Tooltip>
      <Tooltip title={'Compress Mission Pack'} placement='right' arrow>
        <IconButton
          size='small'
          className={classes.small}
          aria-label='feedback'
          disableRipple
          component='label'
          style={{border: '1px solid rgba(255,255,255,0.2)'}}
          onClick={async (e) => {
            setCompressPacksDialogOpen(true)
          }}
        >
          <FolderSpecial style={{marginTop: -2}}/>
        </IconButton>
      </Tooltip>
    </div>
  )
}

const formatTitle = (title) => {
  return title.charAt(0).toUpperCase() + title.slice(1)
}

function EditorSelector(props) {
  const classes = useStyles()
  const [editorState, editorDispatch] = useMissionEditor()

  return (
    <div
      className={editorState.editorSelected === props.title ? `${classes.outlined}` : ''}
    >
      <IconButton
        size='small'
        className={`${classes.small}`}
        aria-label='feedback'
        disabled={!(props.title === 'mission' || (props.title === 'quiz' && editorState.editorSelected === 'question'))}
        disableRipple
        onClick={() => editorDispatch({ type: MissionEditorActions.CHANGE_EDITOR, editorSelected: props.title })}
      >
        {props.icon}
      </IconButton>
    </div>
  )
}

function ToolSelector(props) {
  const classes = useStyles()
  const [editorState, editorDispatch] = useMissionEditor()

  return (
    <div
      className={editorState.toolSelected === props.title ? `${classes.outlined}` : ''}
    >
      <Tooltip title={`${formatTitle(editorState.editorSelected)} ${props.title}`} placement='right' arrow>
        <IconButton
          size='small'
          className={`${classes.small}`}
          aria-label='feedback'
          disableRipple
          onClick={() => editorDispatch({ type: MissionEditorActions.CHANGE_TOOL, tool: props.title })}
        >
          {props.icon}
        </IconButton>
      </Tooltip>
    </div>
  )
}

// Use File System Access API to save where user prefers
async function saveFile(contents, suggestedName = '') {
  // console.log(suggestedName)
  const opts = {
    suggestedName: suggestedName,   // Supported as of Chrome 91
    types: [{
      description: 'JSON file',
      accept: { 'application/json': ['.json'] },
    }],
  }
  const fileHandle = await window.showSaveFilePicker(opts)
  const writable = await fileHandle.createWritable()
  await writable.write(contents)
  await writable.close()
}

export default function EditMissionToolbar(props) {
  const classes = useStyles()
  const [teachersManualDialogOpen, setTeachersManualDialogOpen] = React.useState(false)
  const [compressPacksDialogOpen, setCompressPacksDialogOpen] = React.useState(false)
  const [validatorTestsDialogOpen, setValidatorTestsDialogOpen] = React.useState(false)
  const [editorState, editorDispatch] = useMissionEditor()
  const [debuggerState, debuggerDispatch] = useDebugger()

  const showTargetSelect = (doShow) => {
    debuggerDispatch({ type: DebuggerActions.DEBUG_SHOW_TARGET_SELECT, shouldShow: doShow })
  }

  return (
    <>
      <div className={classes.buttonGroupContainer}>
        <div className={classes.buttonGroup}>
          <Tooltip title={'Open Mission JSON'} placement='right' arrow>
            <IconButton
              size='small'
              className={classes.small}
              aria-label='feedback'
              disableRipple
              component='label'
              onClick={(e) => {
                e.target.value = null
              }}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onDrop={async (e) => {
                e.preventDefault()
                missionEditorController.importJson(e.dataTransfer.files[0], editorState.mission, editorDispatch)
              }}
            >
              <OpenInBrowser />
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={async (e) => {
                  missionEditorController.importJson(e.target.files[0], editorState.mission, editorDispatch)
                }}
              />
            </IconButton>
          </Tooltip>
          <div className={classes.topBorder}></div>

          <EditorSelector title='mission' icon={<Ballot />} />
          <EditorSelector title='objective' icon={<Room />} />
          <EditorSelector title='quiz' icon={<FormatListNumbered />} />
          <EditorSelector title='question' icon={<ContactSupport />} />

          <div className={classes.topBorder}></div>
          {editorState.editorSelected === 'mission' ?
            <React.Fragment>
              <ToolSelector title='Manifest' icon={<Dashboard />} />
              <ToolSelector title='Brief' icon={<Work />} />
              <ToolSelector title='Overview' icon={<Streetview />} />
              <ToolSelector title='Complete' icon={<AssignmentTurnedIn />} />
            </React.Fragment> : null
          }

          {editorState.editorSelected === 'objective' ?
            <React.Fragment>
              <ToolSelector title='Manifest' icon={<Dashboard />} />
              <ToolSelector title='Instructions' icon={<MenuBook />} />
              <ToolSelector title='Code' icon={<Code />} />
              <ToolSelector title='Walkthrough' icon={<DirectionsWalk />} />
              <ToolSelector title='Hints' icon={<EmojiObjects />} />
              <ToolSelector title='Goals' icon={<Assignment />} />
              <ToolSelector title='Complete' icon={<InsertEmoticon />} />
              <ToolSelector title='Solution' icon={<AssignmentTurnedIn />} />
            </React.Fragment> : null
          }

          {editorState.editorSelected === 'question' ?
            <React.Fragment>
              <ToolSelector title='Manifest' icon={<Dashboard />} />
              <ToolSelector title='Question' icon={<LiveHelp />} />
              <ToolSelector title='Answers' icon={<VpnKey />} />
            </React.Fragment> : null
          }

          {editorState.editorSelected === 'quiz' ?
            <React.Fragment>
              <ToolSelector title='Manifest' icon={<Dashboard />} />
              <ToolSelector title='Overview' icon={<Streetview />} />
              <ToolSelector title='Complete' icon={<AssignmentTurnedIn />} />
            </React.Fragment> : null
          }

          <div className={classes.topBorder}></div>

          <Tooltip title={'Download ' + formatTitle(editorState.editorSelected) + ' JSON'} placement='right' arrow>
            <IconButton
              size='small'
              className={classes.small}
              aria-label='feedback'
              disableRipple
              onClick={() => saveFile(missionEditorController.formatSaveJson(editorState),
                editorState.editorSelected === 'objective' ?
                  `${editorState.objective.id}.json` :
                  editorState.editorSelected === 'question' ?
                    `${editorState.question.id}.json` :
                    editorState.editorSelected === 'quiz' ?
                      `${editorState.quiz.id}.json` :
                      editorState.editorSelected === 'mission' ?
                        `${editorState.mission.id}.json` :
                        null
              )}
            >
              <AssignmentReturned />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Connect USB'} placement='right' arrow>
            <IconButton
              size='small'
              className={classes.small}
              aria-label='feedback'
              disableRipple
              onClick={async () => {
                showTargetSelect(true)
              }}
            >
              <Usb />
            </IconButton>
          </Tooltip>

          <div className={classes.topBorder}></div>

          <Tooltip title={'SYNC MISSION'} placement='right' arrow>
            <IconButton
              size='small'
              className={classes.small}
              aria-label='feedback'
              disableRipple
              component='label'
              onClick={async (e) => {
                await editorDispatch({ type: MissionEditorActions.SYNC_MSN })
              }}
            >
              <Sync />
            </IconButton>
          </Tooltip>
          <Tooltip title={'EDITOR SERVER SAVE RESPONSE'} placement='right' arrow>
            <div className={classes.fetchRespContainer}>
              <FetchResp
                fetchStatus={props.editorSaveStatus}
                checkVisibleDuration={300}
                size={23}
              />
            </div>
          </Tooltip>
        </div>
        <BottomButtonGroup {...{setTeachersManualDialogOpen, setCompressPacksDialogOpen, setValidatorTestsDialogOpen}} />
      </div>
      <TeachersManualRipperDialog open={teachersManualDialogOpen} onClose={() => setTeachersManualDialogOpen(false)} />
      <CompressPacksDialog open={compressPacksDialogOpen} onClose={() => setCompressPacksDialogOpen(false)} />
      <ValidatorTestsDialog open={validatorTestsDialogOpen} onClose={() => {
        setValidatorTestsDialogOpen(false)
      }}/>
      <TargetSelectionModal
        open={debuggerState.showTargetSelectDialog}
        onClose={() => showTargetSelect(false)}
      />
    </>
  )
}
