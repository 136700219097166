/** WebUSB based controller, per interface defined in SerialCommsController.js
 *
 */
import { Targets } from './Players'

// Error codes for navigator.usb
const USB_DEV_DISCONNECT = 8
const USB_INVALID_STATE_ERROR = 11  // name="InvalidStateError"

class WebSerialPort {
  constructor(device) {
    this.device = device
    this.interfaceNumber = null
    this.endpointIn = null
    this.endpointOut = null
    this._closePromise = null
    this._resolveClose = null
  }

  readLoop = async () => {
    this._closePromise = new Promise((resolve) => {
      this._resolveClose = resolve
    })

    while (true) {
      try {
        const result = await this.device.transferIn(this.endpointIn, 512)
        this.onReceive(result.data)
      } catch (e) {
        if (e.code === USB_DEV_DISCONNECT || e.code === USB_INVALID_STATE_ERROR) {
          break
        }
      }
    }

    // Read loop terminated. Ensure device is properly closed.
    try {
      if (!this.device.opened) {
        // Already closed
      } else {
        await this.device.close()
      }
      this.onDisconnect()
      this._resolveClose()
    } catch (e) {
      // Immediate close may fail due to transfer in progress (InvalidStateError)
      // WebUSB doesn't provide a good solution here. Retry after a small delay.
      setTimeout(() => {
        try {
          this.device.close()
          // console.log('Retry close success!')
        } catch (e) {
          console.log('WebUSB: close failed: ', e)
        } finally {
          this.onDisconnect()
          this._resolveClose()
        }
      }, 100)
    }
  }

  connect = async (onReceiveCb, onDisconnectCb) => {
    if (this.device.opened) {
      // console.warn('Trying to connect to an already opened device!')
      return
    }
    // console.log('In WebUsbControl.connect(', onReceiveCb, ')')
    this.onReceive = onReceiveCb
    this.onDisconnect = onDisconnectCb
    let isClaimed = false
    try {
      await this.device.open()
    } catch (e) {
      console.error('WebUSB: Error opening device.', e)
      return
    }
    try {
      if (this.device.configuration === null) {
        await this.device.selectConfiguration(1)
      }
      this.device.configuration.interfaces.forEach((element) => {
        element.alternates.forEach((elementalt) => {
          if (elementalt.interfaceClass === 0xFF) {
            this.interfaceNumber = element.interfaceNumber
            isClaimed = element.claimed
            elementalt.endpoints.forEach((elementendpoint) => {
              if (elementendpoint.direction === 'out') {
                this.endpointOut = elementendpoint.endpointNumber
              } else if (elementendpoint.direction === 'in') {
                this.endpointIn = elementendpoint.endpointNumber
              }
            })
          }
        })
      })
    } catch (e) {
      console.error('WebUSB: Error selecting configuration of device')
      return
    }
    if (!isClaimed) {
      try {
        await this.device.claimInterface(this.interfaceNumber)
      } catch (e) {
        console.error('WebUSB: Error claiming interface for device')
        return
      }
    }
    try {
      await this.device.controlTransferOut({
        'requestType': 'class',
        'recipient': 'interface',
        'request': 0x22,
        'value': 0x01,
        'index': this.interfaceNumber,
      })
    } catch (e) {
      console.error('WebUSB: Error Transferring 0x22 request')
      return
    }
    try {
      this.readLoop()
    } catch (e) {
      console.error('WebUSB: Error starting up the read loop.')
    }
  }

  disconnect = async () => {
    await this.device.close()
    await this._waitForClose(1000)
  }

  send = async (data) => {
    try {
      await this.device.transferOut(this.endpointOut, data)
    } catch (e) {
      // console.error('USB Transfer out error', 'usb', 'error', e)  // This happens on disconnect so it's OK
      try {
        await this.device.close()
      } catch (f) {
      }
    }
  }

  _waitForClose = async (timeoutMs) => {
    // If no timeout, just await the close promise
    if (timeoutMs == null) {
      await this._closePromise
      return 'closed'
    }

    // Otherwise, race against the timeout
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => resolve('timeout'), timeoutMs)
    })

    return Promise.race([
      this._closePromise.then(() => 'closed'),
      timeoutPromise,
    ])
  }
}

class WebUsbControl {
  constructor() {
    this.port = null
    this.enabled = !!navigator.usb
    this.desiredTarget = null
    this.deviceDisconnectedCb = null
    this.deviceConnectedCb = null
    this.dataReceivedCb = null

    // Hook connect event (all WebUSB devices)
    if (this.enabled) {
      navigator.usb.addEventListener('connect', (ev) => {
        if (this._isDesiredTarget(ev.device.productName)) {
          this.connectAvailable()
        }
      })
    }
  }

  getPorts = async () => {
    const devices = await navigator.usb.getDevices()
    return devices.map(device => new WebSerialPort(device))
  }

  portDisconnected = async () => {
    this.port = null
    if (this.deviceDisconnectedCb) {
      this.deviceDisconnectedCb()
    }
  }

  disconnect = async () => {
    this.desiredTarget = null
    // Close this connection
    if (this.port) {
      await this.port.disconnect()
      this.port = null
    }
  }

  read = (data) => {
    const dataBuf = new Uint8Array(data.buffer)
    if (this.dataReceivedCb) {
      this.dataReceivedCb(dataBuf)
    }
  }

  send = async (text) => {
    let encdr = new TextEncoder()
    if (this.port && this.port.send) {
      await this.port.send(encdr.encode(text))
    } else {
      console.warn('USB send called with no port', 'usb', 'info')
    }
  }

  connectAvailable = async () => {
    try {
      this.port = null
      const ports = await this.getPorts()
      if (ports.length) {
        // Find first connected port that matches desiredTarget device
        for (const p of ports) {
          if (this._isDesiredTarget(p.device.productName)) {
            this.port = p
          }
        }
        if (!this.port) {
          // console.warn('No previously paired ports available.')
          return
        }
        // console.log('WebUSB: connectAvailable, connecting to ', this.port.device.productName)
        this.connect()
      }
    } catch (e) {
      console.error('WebUsb exception in GetPorts: ', e)
    }
  }

  _isDesiredTarget = (productName) => {
    const target = this._lookupTarget(productName)
    return target === this.desiredTarget || (
      (this.desiredTarget === Targets.USB_CODEBOT) &&
           (target === Targets.USB_CB2 || target === Targets.USB_CB3))
  }

  _lookupTarget = (productName) => {
    let target = null
    switch (productName) {
      case 'CodeBot CB2':
        target = Targets.USB_CB2
        break
      case 'CodeBot':
        target = Targets.USB_CB3
        break
      case 'CodeX':
        target = Targets.USB_CODEX
        break
      case 'CodeAIR':
        target = Targets.USB_CODEAIR
        break
      default:
        target = null  // UNKNOWN!
    }
    return target
  }

  _getDeviceFilters = (target) => {
    const deviceFilterMap = new Map([
      [Targets.USB_CB2,
        [{
          'vendorId': 0x0483,
          'productId': 0x5740,
        }]],
      [Targets.USB_CB3,
        [{
          'vendorId': 0x544d,
          'productId': 0xcb03,
        }]],
      [Targets.USB_CODEBOT,
        [{
          'vendorId': 0x0483,
          'productId': 0x5740,
        },
        {
          'vendorId': 0x544d,
          'productId': 0xcb03,
        }]],
      [Targets.USB_CODEX,
        [{
          'vendorId': 0x544d,
          'productId': 0xc0de,
        }]],
      [Targets.USB_CODEAIR,
        [{
          'vendorId': 0x544d,
          'productId': 0xca00,
        }]],
    ])
    const filters = deviceFilterMap.get(target)
    return filters
  }

  connect = async () => {
    if (!this.port) {
      console.log('connect called with no port')
    }
    try {
      await this.port.connect(this.read, this.portDisconnected)
      if (this.deviceConnectedCb) {
        this.deviceConnectedCb(this._lookupTarget(this.port.device.productName))
      }
    } catch (e) {
      console.error('USB connection error', 'usb', 'error', e)
      this.portDisconnected()
    }
  }

  requestPort = async () => {
    if (!this.desiredTarget) {
      console.log('WebUSB: pairing request with no desired target set.')
      return
    }

    let device = null
    try {
      device = await navigator.usb.requestDevice({
        'filters': this._getDeviceFilters(this.desiredTarget),
      })
      // Check if user plugged-in device while picker dialog was active.
      if (this.port) {
        console.log('Selected port overridden by auto-connect')
        return null
      }
    } catch (e) {
      // If user cancels, we get 'NotFoundError' here.
      if (e.name !== 'NotFoundError') {
        console.error('USB requestDevice error', 'usb', 'error', e)
      }
    }
    if (device) {
      try {
        return new WebSerialPort(device)
      } catch (e) {
        console.error('Error creating a new web serial port from device', e)
      }
    }
    return null
  }

  request = async () => {
    if (!this.port) {
      try {
        const selectedPort = await this.requestPort()
        if (selectedPort) {
          this.port = selectedPort
          this.connect()
        }
      } catch (e) {
        console.error('Error selecting USB device', 'usb', 'info', e.message)
        this.portDisconnected()
      }
    } else {
      console.log('WebUSB: pairing request with port already established.')
    }
  }

  /**
   * Set the desired target connection type (Targets.XXX).
   * Will be used to filter connections on initial pairing and reconnect events.
   */
  setTarget = (target) => {
    this.desiredTarget = target
  }
}

const webUsbController = new WebUsbControl()
export { webUsbController }
