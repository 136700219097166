/** WebSerial based controller, per interface defined in SerialCommsController.js
 *
 */
import { Targets } from './Players'

class WebSerialControl {
  constructor() {
    // Detect if Web Serial is supported.
    this.enabled = 'serial' in navigator

    // Serial Port reference and I/O readers/writers
    this.port = null
    this.reader = null
    this.writer = null

    // Callbacks (client will set these directly).
    this.deviceDisconnectedCb = null // Called when device is physically disconnected.
    this.deviceConnectedCb = null    // Called when device is physically connected.
    this.dataReceivedCb = null       // Called with incoming data (Uint8Array).

    this.desiredTarget = null
    this._closePromise = null
    this._resolveClose = null

    if (this.enabled) {
      navigator.serial.onconnect = (event) => {
        // On any device connection, check if it's a previously paired port reconnecting.
        // If it matches the currently selected target device type, auto reconnect.
        if (this.port || !this.desiredTarget) {
          // Already have connection or not in the market.
          return
        }

        if (this._isDesiredTarget(event.target)) {
          this.connectAvailable()
        }
      }
    }
  }

  /**
   * Set the desired target connection type (Targets.XXX).
   * Will be used to filter connections on initial pairing and reconnect events.
   */
  setTarget = (target) => {
    this.desiredTarget = target
  }

  /**
   * Request user to select a serial port. This must be called in response to
   * a user gesture (e.g. click/tap).
   */
  request = async () => {
    if (!this.enabled) {
      console.warn('Web Serial API not supported in this browser.')
      return
    }
    if (this.port) {
      console.log('WebSerial: pairing request with port already established.')
      return
    }
    if (!this.desiredTarget) {
      console.log('WebSerial: pairing request with no desired target set.')
      return
    }

    try {
      // This opens the serial port picker prompt to the user.
      const selectedPort = await navigator.serial.requestPort({filters: [this._getDeviceFilter(this.desiredTarget)]})

      // Check if user plugged-in device while picker dialog was active.
      if (this.port) {
        console.log('Selected port overridden by auto-connect')
        return
      }
      this.port = selectedPort
    } catch (error) {
      // console.error('User canceled the port selection or no device selected:', error)
      return
    }

    await this.connectAvailable()
  }

  /**
   * Connect to an available serial device that has already been paired.
   * This will attempt to open the port (if not already open) and start reading
   * from it in a loop. The data is passed to this.dataReceivedCb.
   */
  connectAvailable = async () => {
    if (!this.enabled) {
      console.warn('Web Serial API not supported in this browser.')
      return
    }

    // If we don't already have a port, try to get one from already paired devices.
    if (!this.port) {
      const availablePorts = await navigator.serial.getPorts()

      // Find first connected port that matches desiredTarget device
      for (const p of availablePorts) {
        if (this._isDesiredTarget(p)) {
          this.port = p
        }
      }

      if (!this.port) {
        // console.warn('No previously paired ports available.')
        return
      }
    }

    // If port is not open, open it.
    if (!this.port.readable) {
      try {
        await this.port.open({ baudRate: 115200 })
      } catch (error) {
        console.error('Failed to open the serial port:', error)
        return
      }
    }

    // Start reading from the port.
    this._startReading()

    // If a connect callback is set, notify that we're connected.
    if (this.deviceConnectedCb) {
      this.deviceConnectedCb(this._getConnectedTarget(this.port))
    }
  }

  /**
   * Disconnect from the currently open port, cancel any reads, and close it.
   */
  disconnect = async () => {
    this.desiredTarget = null

    if (!this.port) {
      return
    }

    try {
      // Cancel any read operations. Read loop will fully close port before it terminates.
      if (this.reader) {
        // console.log('closing port ', this._getConnectedTarget(this.port))
        await this.reader.cancel()
        await this._waitForClose(1000)
      }
    } catch (error) {
      console.error('Error while disconnecting:', error)
    }
  }

  /**
   * Send text data to the device. Encodes text using TextEncoder.
   */
  send = async (text) => {
    if (!this.enabled || !this.port || !this.port.writable) {
      // console.warn('No open serial port to send data.')
      return
    }

    try {
      // Create a writer if we don't already have one.
      if (!this.writer) {
        this.writer = this.port.writable.getWriter()
      }

      // Encode text (UTF-8 by default).
      const encoder = new TextEncoder()
      const data = encoder.encode(text)

      // Send the data.
      await this.writer.write(data)
    } catch (error) {
      console.error('Error while sending data:', error)
    }
  }

  _isDesiredTarget = (port) => {
    const target = this._getConnectedTarget(port)
    return target === this.desiredTarget || (
      (this.desiredTarget === Targets.USB_CODEBOT) &&
           (target === Targets.USB_CB2 || target === Targets.USB_CB3))
  }

  _getConnectedTarget = (port) => {
    if (!port) {
      return Targets.UNCONNECTED
    }
    const productMap = new Map([
      [0x5740, Targets.USB_CB2],
      [0xcb03, Targets.USB_CB3],
      [0xc0de, Targets.USB_CODEX],
      [0xca00, Targets.USB_CODEAIR],
    ])
    const { usbProductId } = port.getInfo()
    const productName = productMap.get(usbProductId)
    return productName
  }

  _getDeviceFilter = (target) => {
    const deviceFilterMap = new Map([
      [Targets.USB_CB2,
        {
          'usbVendorId': 0x0483,
          'usbProductId': 0x5740,
        }],
      [Targets.USB_CB3,
        {
          'usbVendorId': 0x544d,
          'usbProductId': 0xcb03,
        }],
      [Targets.USB_CODEBOT,
        { // Only CB3 supports WebSerial
          'usbVendorId': 0x544d,
          'usbProductId': 0xcb03,
        }],
      [Targets.USB_CODEX,
        {
          'usbVendorId': 0x544d,
          'usbProductId': 0xc0de,
        }],
      [Targets.USB_CODEAIR,
        {
          'usbVendorId': 0x544d,
          'usbProductId': 0xca00,
        }],
    ])
    const filter = deviceFilterMap.get(target)
    return filter
  }

  /**
   * Continuously read from the device until disconnected or error.
   * This function handles properly closing the serial port.
   */
  _startReading = async () => {
    if (!this.port?.readable) {
      return
    }

    this.reader = this.port.readable.getReader()

    this._closePromise = new Promise((resolve) => {
      this._resolveClose = resolve
    })

    try {
      // Read loop.
      while (true) {
        const { value, done } = await this.reader.read()
        if (done) {
          // Reader is released or the port is closed.
          break
        }
        if (value && this.dataReceivedCb) {
          // Pass received data (Uint8Array) to the callback.
          this.dataReceivedCb(value)
        }
      }
    } catch (error) {
      // Normal - this is how we detect port needs to be closed.
      // console.error('WebSerial read error:', error)
    } finally {
      // Always release the reader when done.
      if (this.reader) {
        await this.reader.releaseLock()
        this.reader = null
      }
      if (this.writer) {
        await this.writer.releaseLock()
        this.writer = null
      }
    }
    await this.port.close()

    this.port = null
    // console.log('Serial port closed.')
    this._resolveClose()
    if (this.deviceDisconnectedCb) {
      this.deviceDisconnectedCb()
    }
  }

  _waitForClose = async (timeoutMs) => {
    if (!this.port) {
      console.warn('WebSerial: _waitForClose unexpectedly closed.')  // Does this happen?
      return 'already closed'
    }

    // If no timeout, just await the close promise
    if (timeoutMs == null) {
      await this._closePromise
      return 'closed'
    }

    // Otherwise, race against the timeout
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => resolve('timeout'), timeoutMs)
    })

    return Promise.race([
      this._closePromise.then(() => 'closed'),
      timeoutPromise,
    ])
  }
}

// Create a single instance for convenience.
const webSerialController = new WebSerialControl()
export { webSerialController }
