/* SerialCommsController - a unified interface for target serial links.

   Non-virtual targets (e.g. UsbDebugBackend - should be renamed FiriaPythonDebugBackend?) communicate
   through a serial link of some kind. This might be WebUSB, WebSerial, WebBLE, etc. The SerialCommsController
   provides a unified interface for target serial links.
*/

import { Targets } from './Players'
import { webUsbController } from './WebUsbControl'
import { webSerialController } from './WebSerialControl'


// Prototype for Serial Interfaces:
/*
class SerialControl_Interface {
  constructor() {
    this.enabled = false

    // Callbacks, will be directly set by client of this object.
    this.deviceDisconnectedCb = null  // ()
    this.deviceConnectedCb = null     // (target)
    this.dataReceivedCb = null        // (data) : receive data as Uint8Array
  }

  setTarget = (target) => {
    // Set the desired target connection type (Targets.XXX).
    // Will be used to filter connections on initial pairing and reconnect events.
  }

  connectAvailable = async () => {
    // Connect to already paired device if there is one available.
    // If successful, starts an async "read loop" which will continuously read data from device and
    // invoke this.dataReceivedCb(data) callback function.
  }

  disconnect = async () => {
    // If connected, close device and terminate read loop.
  }

  send = async (text) => {
    // Send text string to device. Codepoints will be encoded by TextEncoder for serial transmission.
    // No-op if not connected.
  }

  request = async () => {
    // Initiate pairing - trigger user prompt (gesture) to select device
  }
}
*/

/**
 * Return the appropriate serial controller for the given target.
 * - TODO: Might be nice to enable selection among multiple options (e.g. WebUSB/WebSerial) for a given target.
 * -       Lacking this, currently our Targets.USB_CODEBOT multi-device target means that the CB3 is stuck on WebUSB.
 * - TODO: Move CodeX to WebSerial, once we've proven it out with CodeAIR?
 */
export function getSerialController(target) {
  let ctrl = null

  switch (target) {
    case Targets.USB_CB2:
    case Targets.USB_CB3:
    case Targets.USB_CODEBOT:
    case Targets.USB_CODEX:
      ctrl = webUsbController
      break

    case Targets.USB_CODEAIR:
      ctrl = webSerialController
      break

    default:
      console.error('Invalid target for serial controller: ', target)
      break
  }

  return ctrl
}
