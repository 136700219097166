const sharedFilesKey = 'shared-files'

const store = {}
function setFileStoreDefaults() {
  store[sharedFilesKey] = {}
}
setFileStoreDefaults()

function setSharedFileInRepo(sharedFile) {
  store[sharedFilesKey][sharedFile.id] = sharedFile
}

const SHARED_FILE_MODES = Object.freeze({
  SUBMITTED: 'submitted',
  NORMAL: 'normal',
})

function readSharedFileFromRepo(sharedFileId) {
  const file = store[sharedFilesKey][sharedFileId]
  if (!file) {
    return
  }
  let type = SHARED_FILE_MODES.SUBMITTED
  if (file?.sharedTs) {
    type = SHARED_FILE_MODES.NORMAL
  }
  return { ...file, type }
}

function readAllSharedFilesFromRepo() {
  return store[sharedFilesKey]
}

function deleteSharedFileFromRepo(sharedFileId) {
  delete store[sharedFilesKey][sharedFileId]
}

function deleteAllSharedFilesFromRepo() {
  store[sharedFilesKey] = {}
}

export {
  setSharedFileInRepo,
  SHARED_FILE_MODES,
  readSharedFileFromRepo,
  readAllSharedFilesFromRepo,
  deleteSharedFileFromRepo,
  deleteAllSharedFilesFromRepo,
}