import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FiriaMarkdown from '../../FiriaMarkdown'
import Editor from '@monaco-editor/react'

import insertBullets from './tools/insertBullets'
import addCodeBlockNumbers from './tools/addCodeBlockNumbers'
import postProcessHTML from './tools/postProcessHTML'

const walkStepOutlineColor = '#c8c8c8'

const useStyles = makeStyles(theme => ({
  codestepMarkdownContainer: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
  },
  arrow: {
    borderStyle: 'solid',
    borderColor: walkStepOutlineColor + ' transparent transparent transparent',
    borderWidth: '12px 12px 0px 12px',
    transform: 'rotate(180deg)',
    position: 'absolute',
    top: '-12px',
    left: '10px',
  },
  speechBubble: {
    border: '1px solid ' + walkStepOutlineColor,
    borderRadius: '5px',
    position: 'relative',
    padding: '5px',
    marginTop: '12px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    pointerEvents: 'auto',
  },
}))

const TableOfContentsItem = ({ item }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
      <FiriaMarkdown>
        {`* #### ${item.name}`}
      </FiriaMarkdown>
      <div style={{paddingLeft: 7, paddingRight: 7}}>-</div>
      <FiriaMarkdown>
        {`##### ***${item.desc}***`}
      </FiriaMarkdown>
    </div>
  )
}

const ManualQuestion = ({ qData, index }) => {
  const classes = useStyles()
  return (
    <div key={index} className={classes.questionContainer}>
      <FiriaMarkdown>
        {`***Question ${index+1}:*** ${qData.q}`}
      </FiriaMarkdown>
      <div style={{marginTop:-8}}>
        {qData.answers.map((a, idx) =>
          <FiriaMarkdown key={idx}>
            {`${a.correct ? '<span class="material-icons" style="font-size: 2em;vertical-align: middle; color: green;">done</span>' : '<span class="material-icons" style="font-size: 2em;vertical-align: middle; color: red;">close</span>'} ${a.a}`}
          </FiriaMarkdown>
        )}
      </div>
    </div>

  )
}

const ManualQuiz = ({ data }) => {
  return (
    <div>
      {data.questions.map((q, index) => <ManualQuestion qData={q.obj} index={index} key={index}/>)}
    </div>
  )
}

const Solution = ({ solution }) => {
  const numberedSolution = addCodeBlockNumbers({ code: solution })
  return (
    <FiriaMarkdown>{'### Solution: \n```\n'+ numberedSolution+'\n```'}</FiriaMarkdown>
  )
}

const Goals = ({ goals }) => {
  return (
    <>
      <div style={{clear: 'both'}}>
        <FiriaMarkdown>{`### Goal${goals.length > 1 ? 's' : ''}: \n`}</FiriaMarkdown>
      </div>
      <div>
        {goals.map((goal, idx) => <FiriaMarkdown key={idx}>{`* ${insertBullets({ md: goal.md })}`}</FiriaMarkdown>)}
      </div>
    </>
  )
}

const Hints = ({ hints }) => {
  return (
    <>
      <FiriaMarkdown>{`### Hint${hints.length > 1 ? 's' : ''}: \n`}</FiriaMarkdown>
      <div>
        {hints.map((hint, idx) => <FiriaMarkdown key={idx}>{`* ${insertBullets({ md: hint })}`}</FiriaMarkdown>)}
      </div>
    </>
  )
}

const ToolsFound = ({ tools }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
      <FiriaMarkdown >{'### Tools Found:'}</FiriaMarkdown>
      <div style={{paddingLeft: 7}}>{tools.map((tool, idx) =>{
        return `${tool}${idx === tools.length - 1 ? '' : ', '}`
      })}</div>
    </div>
  )
}

const ToolboxItem = ({ itemInfo }) => {
  const { hint } = itemInfo

  var md = ''
  if (typeof hint.props.children === 'string'){
    const docButtonIndexes = [...hint.props.children.matchAll(/```react.*?\s*((?:<.*(?:PyDocsButton|DocsButton).*\/>\s*)+)\s```/g)].reverse()
    md = [...hint.props.children]
    docButtonIndexes.forEach((val) => {
      const docButtonStart = val[1]
      const buttonsString = docButtonStart.split('```')[0]
      const buttons = buttonsString.split('ocsButton')
      buttons.reverse()
      buttons.pop()
      buttons.forEach((string) => {
        const name = string.split('name=\'')[1].split('\'')[0]
        const ref = string.split('ref=\'')[1].split('\'')[0]
        md.splice(
          val.index,
          0,
          `\r\n\r\n#### ${name}: <a href=${ref}>${ref}</a>\r\n\r\n`
        )
      })
    })
    md = md.join('')
  }
  return (
    <div>
      {/* {
        Object.entries(appearances).length > 0 ?
          <div>
            <FiriaMarkdown>{'### ***Referenced In:***'}</FiriaMarkdown>
            {Object.entries(appearances).map(([packTitle, missions]) => {
              return (
                <div key={packTitle}>
                  <ul>
                    <li>
                      <FiriaMarkdown>{`### ${packTitle}`}</FiriaMarkdown>
                    </li>
                    <ul>
                      {Object.entries(missions).map(([missionTitle, flowList]) => {
                        return (
                          <li key={missionTitle}>
                            <FiriaMarkdown>{`#### ${missionTitle}`}</FiriaMarkdown>
                            <ul>
                              {flowList.map((item, idx) => {
                                return (
                                  <li key={idx}>
                                    <FiriaMarkdown>{`${item}`}</FiriaMarkdown>
                                  </li>
                                )
                              })}
                            </ul>
                          </li>
                        )
                      })}
                    </ul>
                  </ul>
                </div>)
            })}
          </div>:
          null
      } */}
      <FiriaMarkdown>{md}</FiriaMarkdown>
      <hr style={{border: '2px solid green', clear:'both', marginTop: 35}} />
    </div>
  )
}

const CodeSteps = ({ codesteps }) => {
  const classes = useStyles()
  return (
    codesteps.map((step, idx) => {
      return (
        <>
          <div id='ripperStepStart'></div>
          <div key={idx} id={`stepMarkdown;${step.marker}`} style={{paddingLeft: 25}}>
            <div className={classes.speechBubble} >
              <div className={classes.arrow} />
              <div className={classes.codestepMarkdownContainer} >
                <FiriaMarkdown>{step.md}</FiriaMarkdown>
              </div>
            </div>
          </div>
          <div id='ripperStepEnd'></div>
        </>
      )
    })
  )
}

const CodeTrek = ({ trekData }) => {
  const { code, codesteps } = trekData

  const formatCode = (numberedCode) => {
    const markdownIndexes = [...numberedCode.matchAll(/#@/g)].map(a => a.index)
    const formattedCode = [...numberedCode]

    markdownIndexes.reverse()
    markdownIndexes.forEach(idx =>
      formattedCode.splice(idx, 0, '\r\n').join('')
    )
    return formattedCode.join('')
  }

  const numberedCode = addCodeBlockNumbers({ code })

  return (
    <>
      <FiriaMarkdown>{'### CodeTrek: \n'}</FiriaMarkdown>
      <div id='ripperCodeTrekStart'></div>
      <div>
        {codesteps.length > 0 ?
          <CodeSteps codesteps={codesteps} />:null
        }
        <FiriaMarkdown>{'```\n'+formatCode(numberedCode)+'\n```\n'}</FiriaMarkdown>
      </div>
      <div id='ripperCodeTrekEnd'></div>
    </>
  )
}


const HTMLSubject = (props) => {
  const timeout = React.useRef(1)

  const getCSS = (doc) => {
    const children = []
    for (var x = 0; x < document.head.children.length; x++) {
      children.push(document.head.children[x])
    }
    const styleClasses = children.filter(el => el.tagName === 'STYLE')

    const cssObj = {}
    var allClasses = []
    var allElements = doc.querySelectorAll('*')
    for (var i = 0; i < allElements.length; i++) {
      var classes = allElements[i].className.toString().split(/\s+/)
      for (var j = 0; j < classes.length; j++) {
        var cls = classes[j]
        if (cls && allClasses.indexOf(cls) === -1) {
          allClasses.push(cls)
        }
      }
    }

    allClasses.forEach((el) => {
      const styleElement = styleClasses.find(stEl => stEl.innerHTML.includes(el))
      if (!styleElement){
        return
      }
      cssObj[styleElement.innerText] = styleElement.innerHTML
    })
    return cssObj
  }

  const getHTML = async () => {
    const doc = document.getElementById('contentripper')
    const css = getCSS(doc)
    var content = doc.innerHTML

    const imageList = []
    const trekMarkdownList = []

    const getImgSizes = (doc) => {
      for (let child of doc.children) {
        getImgSizes(child)

        if (child.id.startsWith('stepMarkdown')) {
          const size = child.getBoundingClientRect()
          trekMarkdownList.push(Math.ceil(size.height))
        }
        if (child.tagName === 'IMG'){
          const size = child.getBoundingClientRect()
          const childData = {
            src: child.src.split('/')[child.src.split('/').length - 1],
            height: Math.floor(size.height),
            width: Math.floor(size.width),
          }
          imageList.push(childData)
        }
        if (child.tagName === 'DIV'){
          if (child?.style?.backgroundImage && child?.style?.backgroundImage.indexOf('pub/') !== -1){
            const size = child.getBoundingClientRect()
            const childData = {
              src: child.style.backgroundImage.split('/')[child.style.backgroundImage.split('/').length - 1].split('")')[0],
              height: Math.floor(size.height),
              width: Math.floor(size.width),
            }
            imageList.push(childData)
          }
        }
      }
    }

    getImgSizes(doc)

    let waitLonger = false
    imageList.forEach((img) => {
      if (img.height === 0 || img.width === 0){
        waitLonger = true
      }
    })

    if (waitLonger){
      console.log('waiting longer!')
      timeout.current += 15
      harvestHTML()
      return
    }

    props.imageSizeList.current.push(...imageList)

    if (timeout.current - 7 > 0){
      timeout.current =- 7
    }
    postProcessHTML({
      html: content,
      imagesPath: props.absImagePath,
      onFinish: html => props.successfulRip(css, html),
    })
  }

  const harvestHTML = (to=timeout.current) => {
    setTimeout(()=>{
      getHTML()
    }, to)
  }

  React.useEffect(() => {
    if (props.task.type !== 'editor'){
      harvestHTML()
    }
  })

  const mount = (editor) => {
    editor.getModel().setValue(props.task.taskContent)
    // Wait for the editor to mount before ripping html
    harvestHTML(1000)
  }

  return (
    <div id='contentripper' style={{width: '630px', height: '100vh'}}>
      {props.task.type === 'md' ?
        <FiriaMarkdown>
          {props.task.taskContent}
        </FiriaMarkdown>:
        null
      }
      {props.task.type === 'editor' ?
        <Editor
          onMount={mount}
          theme={'light'} // "dark or "light"
          language={'python'}
          options={{
            readOnly: true,
            cursorWidth:0,
            selectionHighlight: true,
            automaticLayout: true,
            codeLens: false,
            scrollBeyondLastLine: false,
            minimap: { enabled: false },
          }}
          wrappingStrategy={'advanced'}
          overviewRulerLanes={0}
          value={props.task.taskContent}
        />:
        null
      }
      {props.task.type === 'spacer' ?
        <FiriaMarkdown>
          {`\n<div style="font-size: ${props.task.taskContent}px"><br></br></div>`}
        </FiriaMarkdown>:
        null
      }
      {props.task.type === 'goals' ?
        <Goals goals={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'hints' ?
        <Hints hints={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'solution' ?
        <Solution solution={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'quiz' ?
        <ManualQuiz data={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'codeTrek' ?
        <CodeTrek trekData={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'toolboxItem' ?
        <ToolboxItem itemInfo={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'toolsFound' ?
        <ToolsFound tools={props.task.taskContent}/>:
        null
      }
      {props.task.type === 'toc' ?
        <TableOfContentsItem item={props.task.taskContent}/>:
        null
      }
    </div>
  )
}

export default HTMLSubject