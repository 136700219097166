// Coding Panel
import React, { useRef } from 'react'
import { useCodePanel, CodePanelActions } from './contexts/CodePanelContext'
import { useFileManagement, FileManagementActions } from './contexts/FileManagementContext'
import Editor from '@monaco-editor/react'
import { useUserConfig } from './contexts/UserConfigContext'
import CodingHeader from './CodingHeader'
import CodingTabs from './CodingTabs'
import DirectoryPanel from './DirectoryPanel'
import { loader } from '@monaco-editor/react'
import { NewFileModal, ShareFileModal } from './FileSystemModals'
import { useDebugger } from './contexts/DebuggerContext'
import { TargetStates, TargetModes } from './TargetInterface'
import { FileHelpSvg } from './assets/SvgIcons'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Dialog, IconButton, Divider } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ShareIcon from '@material-ui/icons/Share'
import { useLogin } from './contexts/LoginContext'
import { WelcomePanelModal } from './ToolBarModal'
import { AboutPanelModal } from './ToolBarModal'
import ButtonBase from '@material-ui/core/ButtonBase'
import { readSharedFileFromRepo } from './content-manager/code-files/code-file-store'
import { readFlowStoreByFlowId, readMissionStoreByMissionId } from './content-manager/lesson-content/lesson-content-store'
import { getMissionPackWithMissionId } from './content-manager/lesson-content/lesson-content-use-cases'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { SHARED_FILE_MODES } from './content-manager/code-files/code-file-store'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles((theme) => {
  return ({
    fill: {
      stroke: 'white',
    },
    firiaLogoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50%',
    },
    firiaLogo: {
      width: '65%',
      height: '65%',
    },
    fileHelpSvg: {
      position: 'absolute',
      top: 3,
      left: 3,
    },
    svgContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.type === 'dark' ? '#1E1E1E' : '#c7c7c7',
      top: 28,
      left: 0,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    maxContainerSize: {
      height: '100%',
      width: '100%',
    },
    sharedFileInfoBarButton: {
      '&:hover, &:focusVisible': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
  })
})

loader.config({ paths: { vs: 'monaco-editor/min/vs' } })
export let editorInstance = null

function BooleanFieldValue({bool}) {
  if (bool) {
    return <DoneIcon/>
  }
  return <ClearIcon/>
}

function FileInfoBarField({ title, children }) {
  return (
    <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 3}}>
        <Typography variant='caption' color='textSecondary' style={{fontSize: 12}}>{title}</Typography>
      </div>
      <div style={{width: 10}}></div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flex: 4}}>
        {typeof children === 'string' || typeof children === 'number' ?
          <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 14, textOverflow: 'ellipsis'}}>{children}</Typography>:
          typeof children === 'boolean' ? <BooleanFieldValue bool={children}/> :
            children
        }
      </div>
    </div>
  )
}

function RuntimeErrorDialog({ open, handleClose, error = '' }) {
  let errorObj = {name: '', message: '', codeText: '', stack: ''}
  try {
    errorObj = JSON.parse(error)
  } catch (err) {
    //
  }
  return (
    <Dialog open={open}>
      <div style={{minWidth: 400, minHeight: 300, padding: '10px 20px'}}>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
          <Typography variant='h6'>{'Last Error Message'}</Typography>
          <IconButton onClick={handleClose} size='small'>
            <ClearIcon/>
          </IconButton>
        </div>
        <div style={{height: 5}}></div>
        <Divider />
        <div style={{height: 5}}></div>
        <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 14, textOverflow: 'ellipsis'}}>{`${errorObj.name}: ${errorObj.message}`}</Typography>
        {errorObj?.codeText ?
          <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 14, textOverflow: 'ellipsis'}}>{`Code Text: ${errorObj?.codeText}`}</Typography>:
          null}
        <div style={{paddingLeft: 10}}>
          {errorObj?.stack.split('\n').map(line => <Typography variant='subtitle1' style={{fontSize: 14, textOverflow: 'ellipsis'}}>{line}</Typography>)}
        </div>
      </div>
    </Dialog>
  )
}

const EXPANDED_SHARED_FILE_INFO_BAR_HEIGHT = 50
const EXPANDED_SUBMITTED_SHARED_FILE_INFO_BAR_HEIGHT = 150
function ExpandedSharedFileInfoBar({ openCopyDialog }) {
  const [codePanelState] = useCodePanel()
  const sharedFileMetaData = readSharedFileFromRepo(codePanelState.tabs.focused) ?? {}
  const [runtimeErrorDialogOpen, setRuntimeErrorDialogOpen] = React.useState(false)
  let height = EXPANDED_SHARED_FILE_INFO_BAR_HEIGHT
  if (sharedFileMetaData.type === SHARED_FILE_MODES.SUBMITTED) {
    height = EXPANDED_SUBMITTED_SHARED_FILE_INFO_BAR_HEIGHT
  }

  return (
    <div style={{height:height - 1, width: '100%', display: 'flex', justifyContent:'center'}}>
      {sharedFileMetaData.type === SHARED_FILE_MODES.SUBMITTED ?
        <div style={{padding: 5, width: 750, display: 'flex'}}>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <FileInfoBarField title='Mission Pack'>
              {getMissionPackWithMissionId(sharedFileMetaData.missionId)?.title}
            </FileInfoBarField>
            <FileInfoBarField title='Mission'>
              {readMissionStoreByMissionId(sharedFileMetaData.missionId)?.title}
            </FileInfoBarField>
            <FileInfoBarField title='Objective'>
              {readFlowStoreByFlowId(sharedFileMetaData.flowId)?.obj?.title}
            </FileInfoBarField>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <FileInfoBarField title='Submitted On'>
              <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 12, textOverflow: 'ellipsis'}}>{new Date(sharedFileMetaData.submittedTs).toLocaleString()}</Typography>
            </FileInfoBarField>
            <FileInfoBarField title='Validated'>
              {sharedFileMetaData.validated}
            </FileInfoBarField>
            <FileInfoBarField title='Authenticated'>
              {sharedFileMetaData.validationAuthenticated}
            </FileInfoBarField>
            {!!sharedFileMetaData.lastRuntimeErrorMessage ?
              <Button onClick={() => setRuntimeErrorDialogOpen(true)} variant='contained'>View Errors</Button>:
              <FileInfoBarField title='No Errors'>
                {!sharedFileMetaData.lastRuntimeErrorMessage}
              </FileInfoBarField>
            }
          </div>
        </div>:
        <div style={{width: '100%'}}>
          <Button onClick={openCopyDialog} style={{width: '100%', height: '100%', borderRadius: 0, textTransform: 'none'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5}}>
              <FileCopyIcon style={{fontSize: 16}} />
              <Typography>{'Make A Copy'}</Typography>
              <div style={{width: 16}} />
            </div>
          </Button>
        </div>}
      <RuntimeErrorDialog open={runtimeErrorDialogOpen} handleClose={() => setRuntimeErrorDialogOpen(false)} error={sharedFileMetaData.lastRuntimeErrorMessage} />
    </div>
  )
}

const SHARED_FILE_INFO_FOOTER_HEIGHT = 36
function SharedFileInfoBar({ expanded, toggleExpanded, openCopyDialog }) {
  const classes = useStyles()
  const [codePanelState] = useCodePanel()
  if (!codePanelState.sharedFileMode) {
    return null
  }


  return (
    <div style={{border: '1px solid rgba(255,255,255,0.05)', backgroundColor: 'rgba(255,255,255,0.03)'}}>
      {expanded ? <>
        <ExpandedSharedFileInfoBar openCopyDialog={openCopyDialog}/>
        <div style={{borderBottom: '1px solid rgba(255,255,255,0.05)', width: '100%', height:1}}></div>
      </>:null}
      <ButtonBase onClick={toggleExpanded} className={classes.sharedFileInfoBarButton} style={{height:SHARED_FILE_INFO_FOOTER_HEIGHT, width: '100%', display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems:'center'}}>
          <ShareIcon />
          <div style={{width: 5}}></div>
          <Typography>You're viewing another user's file.</Typography>
        </div>
        {!expanded ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
      </ButtonBase>
    </div>
  )
}

export default function CodingPanel(props) {
  const classes = useStyles()
  const [loginState] = useLogin()
  const panelElement = useRef(null)
  const [codePanelState, codePanelDispatch] = useCodePanel()
  const [fileManagementState, fileManagementDispatch] = useFileManagement()
  const [userConfigState] = useUserConfig()
  const [directoryOpen, setDirectoryOpen] = React.useState(false)
  const [welcomeOpen, setWelcomeOpen] = React.useState(false)
  const [aboutOpen, setAboutOpen] = React.useState(false)
  const [newFileDialogMode, setNewFileDialogMode] = React.useState(null)
  const [shareFileDialogOpen, setShareFileDialogOpen] = React.useState(false)
  const [codeLanguage, setCodeLanguage] = React.useState(userConfigState.codeLanguage)
  const [debuggerState] = useDebugger()
  const [readOnly, setReadOnly] = React.useState(false)
  const [absoluteFilePath, setAbsoluteFilePath] = React.useState(null)
  const [sharedFileInfoBarExpanded, setSharedFileInfoBarExpanded] = React.useState(true)
  const readOnlyKeyDown = React.useRef(null)
  const readOnlyOnDidAttemptEdit = React.useRef(null)

  React.useEffect(() => {
    const metadata = fileManagementState.tree?.get(codePanelState.tabs.focused)
    // Update name & language if any of the tab's metadata changes
    if (!!codePanelState.tabs.focused && !!metadata) {
      const { codeLanguage } = metadata
      setCodeLanguage(codeLanguage)
    }
  }, [codePanelState.tabs.focused, fileManagementState.tree])

  /**
   * Updates the editor's read only when the user runs or debugs code
  */
  React.useEffect(() => {
    if (!codePanelState.editorInstance) {
      return
    }
    setReadOnly(debuggerState.mode !== TargetModes.DISCONNECTED && debuggerState.targetState !== TargetStates.STOPPED)
  }, [debuggerState.mode, debuggerState.targetState, codePanelState.editorInstance])

  // This exists outside of CodePanelContext because we need access to debuggerState, and nobody else
  // needs to know about read only mode (as of right now)
  React.useEffect(() => {
    const triggerWarning = (messageContribution) => {
      messageContribution.showMessage(
        `Please stop ${debuggerState.mode === TargetModes.DEBUG ? 'debugging' : 'running'} before editing your code!`,
        codePanelState.editorInstance.getPosition()
      )
    }

    if (codePanelState.editorInstance && readOnly) {
      const messageContribution = codePanelState.editorInstance.getContribution('editor.contrib.messageController')
      // Ideally we'd be relying on onDidAttemptReadOnlyEdit alone, however it's only triggering on paste and backspace
      // in our version of monaco, and we need it to trigger for all keys. It's worth revisiting if we upgrade versions.
      readOnlyKeyDown.current = codePanelState.editorInstance.onKeyDown((event) => {
        // Warn except for navigation keystrokes
        if (!(event.code.startsWith('Arrow') || event.code.startsWith('Page') || event.code === 'Home' || event.code === 'End')) {
          triggerWarning(messageContribution)
        }
      })
      readOnlyOnDidAttemptEdit.current = codePanelState.editorInstance.onDidAttemptReadOnlyEdit(() => {
        triggerWarning(messageContribution)
      })
    }

    return () => {
      if (readOnlyKeyDown.current) {
        readOnlyKeyDown.current.dispose()
      }
      if (readOnlyOnDidAttemptEdit.current) {
        readOnlyOnDidAttemptEdit.current.dispose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codePanelState.editorInstance, readOnly])

  const fileManagementDispatchRef = React.useRef()
  React.useEffect(() => {
    fileManagementDispatchRef.current = fileManagementDispatch
  }, [fileManagementDispatch])

  /**
   * Build an absolute file path when the focused tab changes,
   * use and reference it as cached model id
   */
  React.useEffect(() => {
    const getFilePath = async () => {
      let fp = await fileManagementDispatchRef.current({ type: FileManagementActions.GET_ABSOLUTE_FILE_PATH, fileUid: codePanelState.tabs.focused })
      // I know, I know, this looks weird. Why are we putting the UID before the file path? Why wouldn't we just use the UID?
      // Alright hear me out, we can't just use the UID. As of monaco 0.22.3, the error marker pulls the file name from the editor instance path.
      // That means the path has to be a regularly formatted path so the editor can strip the filename from the end.
      // However, we were running into issues with the path when renaming and deleting files. On a rename, one file would swap paths
      // which caused issues when another file was created with the recently vacated path. So we're left with this monstrosity. Apologies :(
      // Jeff Lloyd 2021-12-08
      setAbsoluteFilePath(
        `${codePanelState.tabs.focused}/${fp}` ??
        `nologin/${codePanelState.tabs.focused}/Untitled Program`
      )
    }

    getFilePath()
  }, [codePanelState.tabs.focused])

  const calculateEditorHeight = () => {
    if (!codePanelState.sharedFileMode) {
      return 'calc(100% - 64px)'
    }

    if (sharedFileInfoBarExpanded) {
      if (codePanelState.sharedFileMode === SHARED_FILE_MODES.SUBMITTED) {
        return `calc(100% - 64px - ${EXPANDED_SUBMITTED_SHARED_FILE_INFO_BAR_HEIGHT+SHARED_FILE_INFO_FOOTER_HEIGHT}px)`
      } else {
        return `calc(100% - 64px - ${EXPANDED_SHARED_FILE_INFO_BAR_HEIGHT+SHARED_FILE_INFO_FOOTER_HEIGHT}px)`
      }
    }

    return `calc(100% - 64px - ${SHARED_FILE_INFO_FOOTER_HEIGHT}px)`
  }

  return (
    <div ref={panelElement} id='coding-panel' style={{ height: '100%' }}>
      <CodingHeader
        openDirectoryDialog={() => setDirectoryOpen(true)}
        setNewFileDialogMode={setNewFileDialogMode}
        setShareFileDialogOpen={setShareFileDialogOpen}
        openWelcomeDialog={() => setWelcomeOpen(true)}
        openAboutDialog={() => setAboutOpen(true)}
      />
      <CodingTabs
        setDirectoryOpen={setDirectoryOpen}
      />
      {!codePanelState.tabs.focused && loginState?.user ?
        <div className={`${classes.svgContainer} ${classes.maxContainerSize}`}>
          <FileHelpSvg
            className={classes.fileHelpSvg}
            viewBox='0 0 210 297'
            width='500'
            height='700'
            fill={userConfigState.theme === 'dark' ? 'white' : '#1E1E1E'}
          />
          <div className={classes.firiaLogoContainer}>
            <img alt={''} className={classes.firiaLogo} src={'pub/logo-min.svg'} />
          </div>
        </div> :
        null
      }
      <div
        className={classes.maxContainerSize}
        style={{ display: !codePanelState.tabs.focused && loginState?.user ? 'none' : null, height: calculateEditorHeight() }} // 64px = tab bar + menu + buffer
      >
        {fileManagementState.fileSystemReady ?
          <Editor
            height='100%'
            onMount={(editor, monaco) => codePanelDispatch({
              type: CodePanelActions.EDITOR_INSTANCE_SET,
              editorInstance: editor,
              monacoInstance: monaco,
            })}
            theme={'vs-' + userConfigState.theme} // "dark or "light"
            language={codeLanguage}
            path={absoluteFilePath}
            options={{
              readOnly: readOnly || codePanelState.sharedFileMode,
              automaticLayout: true,
              mouseWheelZoom: true,
              glyphMargin: true,
              lineNumbersMinChars: 3,
            }}
            onChange={code => codePanelDispatch({ type: CodePanelActions.EDITOR_CONTENT_CHANGED, code })}
          />:null}
      </div>
      <SharedFileInfoBar
        expanded={sharedFileInfoBarExpanded}
        toggleExpanded={() => setSharedFileInfoBarExpanded(x => !x)}
        openCopyDialog={() => setNewFileDialogMode('Make A Copy')}
      />
      <DirectoryPanel
        open={directoryOpen}
        setOpen={setDirectoryOpen}
      />
      <NewFileModal
        mode={newFileDialogMode}
        onClose={() => setNewFileDialogMode(null)}
        parentFolder={fileManagementState.root}
      />
      <ShareFileModal
        open={shareFileDialogOpen}
        onClose={() => setShareFileDialogOpen(null)}
      />
      <WelcomePanelModal
        open={welcomeOpen}
        onClose={() => setWelcomeOpen(false)}
      />
      <AboutPanelModal
        open={aboutOpen}
        onClose={() => setAboutOpen(false)}
      />
    </div>
  )
}
